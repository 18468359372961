/* eslint-disable */

import React from "react";
import validator from "validator";
import { validated } from "react-custom-validation";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Sidebar from "./Sidebar";
const isEmpty = (value) => (value === "" ? "This field is required." : null);
const isEmail = (email) =>
  validator.isEmail(email) ? null : "This is not a valid email.";
const phonenumberPattern = /^[0-9]{6,14}$/;
const isMobile = (mobile) =>
  mobile.match(phonenumberPattern)
    ? null
    : "This is not a valid mobile number.";
function validationConfig(props) {
  const { firstname, lastname, email, mobile } = props.fields;
  return {
    fields: ["firstname", "lastname", "email", "mobile"],

    validations: {
      firstname: [[isEmpty, firstname]],
      lastname: [[isEmpty, lastname]],
      mobile: [
        [isEmpty, mobile],
        [isMobile, mobile],
      ],
      email: [
        [isEmpty, email],
        [isEmail, email],
      ],
    },
  };
}

class Form extends React.Component {
  constructor(props) {
    var Maxdate = new Date();
    Maxdate.setFullYear(Maxdate.getFullYear() - 10);
    super(props);
    this.state = {
      status: "Loading",
    };
  }

  render() {
    const { fields, onChange, onValid, onInvalid, $field, $validation } =
      this.props;

    let errMsgFirstname, errMsgLastname, errMsgEmail, errMsgMobile;

    if ($validation.firstname.error.reason !== undefined) {
      errMsgFirstname = $validation.firstname.show && (
        <span className="error">{$validation.firstname.error.reason}</span>
      );
    }

    if ($validation.lastname.error.reason !== undefined) {
      errMsgLastname = $validation.lastname.show && (
        <span className="error">{$validation.lastname.error.reason}</span>
      );
    }

    if ($validation.email.error.reason !== undefined) {
      errMsgEmail = $validation.email.show && (
        <span className="error">{$validation.email.error.reason}</span>
      );
    }

    if ($validation.mobile.error.reason !== undefined) {
      errMsgMobile = $validation.mobile.show && (
        <span className="error">{$validation.mobile.error.reason}</span>
      );
    }

    return (
      <div className="container-one">
        <div className="tab-content">
          <div className="tab-pane account-tab-section fade in active">
            <div className="tab_mobrow main_tabin">
              <div className="account_sec">
                <Sidebar pageName="my-account" {...this.props} />
                <div className="accsec_right">
                  <div className="acc-inform">
                    {/* profile-info-div - end */}
                    <div className="profile-info-div">
                      <h4 className="form_grouptt">Your Account Information</h4>
                      <div className="form-group half-fg">
                        <div className="row">
                          <div className="col-md-6">
                            <div className="focus-out">
                              <input
                                type="text"
                                placeholder="First Name"
                                name="userfirstname"
                                className="form-control input-focus"
                                value={fields.firstname || ""}
                                {...$field("firstname", (e) =>
                                  onChange("firstname", e.target.value)
                                )}
                              />
                              {errMsgFirstname}
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="focus-out">
                              <input
                                type="text"
                                placeholder="Last Name"
                                name="userlastname"
                                value={fields.lastname || ""}
                                {...$field("lastname", (e) =>
                                  onChange("lastname", e.target.value)
                                )}
                                className="form-control input-focus"
                              />
                              {errMsgLastname}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="form-group half-fg">
                        <div className="row">
                          <div className="col-md-6">
                            <div className="focus-out">
                              <input
                                type="email"
                                placeholder="Email Address"
                                name="user_email"
                                value={fields.email || ""}
                                {...$field("email", (e) =>
                                  onChange("email", e.target.value)
                                )}
                                className="form-control input-focus"
                              />
                              {errMsgEmail}
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="focus-out">
                              <input
                                type="number"
                                placeholder="Mobile Number"
                                name="user_phone"
                                value={fields.mobile || ""}
                                {...$field("mobile", (e) =>
                                  onChange("mobile", e.target.value)
                                )}
                                className="form-control input-focus"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="form-group btn-last-group">
                        <div className="row">
                          <div className="col-md-6">
                            <button
                              type="button"
                              className="myaccount_update button"
                              onClick={(e) => {
                                e.preventDefault();
                                this.props.$submit(onValid, onInvalid);
                              }}
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* profile-info-div - end */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
Form = validated(validationConfig)(Form);

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};
Form.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
};
export default connect(mapStateToProps, mapDispatchToProps)(Form);
