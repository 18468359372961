/* eslint-disable */
import { takeEvery, call, put } from "redux-saga/effects";
import { GET_BUSINESSREGISTRATION, SET_BUSINESSREGISTRATION } from "../actions";
import { apiUrl } from "../components/Helpers/Config";
import Axios from "axios";

export const watchGetBusinessRegistration = function* () {
  yield takeEvery(GET_BUSINESSREGISTRATION, workerGetBusinessRegistration);
};

function* workerGetBusinessRegistration({ formPayload }) {
  try {
    const result = yield call(getBusinessRegistration, formPayload);
    var resultArr = [];
    resultArr.push(result.data);
    yield put({ type: SET_BUSINESSREGISTRATION, value: resultArr });
  } catch {
    console.log("login failed");
  }
}

function getBusinessRegistration(formPayload) {
  return Axios.post(apiUrl + "promobuddy/businessregistration", formPayload);
}
