import { SET_USERPROMOTIONLIST } from "../actions";

const userpromotionlist = (state = [], action) => {
  switch (action.type) {
    case SET_USERPROMOTIONLIST:
      return [...action.value];
    default:
      return state;
  }
};

export default userpromotionlist;
