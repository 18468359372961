import { combineReducers } from "redux";
import promotionlist from "./promotionlist";
import voucherlist from "./voucherlist";
import cashbacklist from "./cashbacklist";
import restaurantlist from "./restaurantlist";
import taglist from "./taglist";
import businessregistration from "./businessregistration";
import businesslogin from "./businesslogin";
import login from "./login";
import registration from "./registration";
import userdetails from "./userdetails";
import customerdetails from "./customerdetails";
import userpromotionlist from "./userpromotionlist";
import uservoucherlist from "./uservoucherlist";
import favourite from "./favourite";
import favouritelist from "./favouritelist";
import changepassword from "./changepassword";
import changeuserpassword from "./changeuserpassword";
import forgetpassword from "./forgetpassword";
import forgetbuspassword from "./forgetbuspassword";

const rootReducer = combineReducers({
  promotionlist: promotionlist,
  voucherlist: voucherlist,
  restaurantlist: restaurantlist,
  taglist: taglist,
  businessregistration: businessregistration,
  businesslogin: businesslogin,
  login: login,
  registration: registration,
  userdetails: userdetails,
  customerdetails: customerdetails,
  userpromotionlist: userpromotionlist,
  uservoucherlist: uservoucherlist,
  cashbacklist: cashbacklist,
  favourite: favourite,
  favouritelist: favouritelist,
  changepassword: changepassword,
  changeuserpassword: changeuserpassword,
  forgetpassword: forgetpassword,
  forgetbuspassword: forgetbuspassword,
});

export default rootReducer;
