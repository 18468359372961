/* eslint-disable */
import { takeEvery, call, put } from "redux-saga/effects";
import { GET_CHANGEUSERPASSWORD, SET_CHANGEUSERPASSWORD } from "../actions";
import { apiUrl } from "../components/Helpers/Config";
import Axios from "axios";

export const watchGetChangeUserPassword = function* () {
  yield takeEvery(GET_CHANGEUSERPASSWORD, workerGetChangeUserPassword);
};

function* workerGetChangeUserPassword({ formPayload }) {
  try {
    const result = yield call(getChangeUserPassword, formPayload);
    var resultArr = [];
    resultArr.push(result.data);
    yield put({ type: SET_CHANGEUSERPASSWORD, value: resultArr });
  } catch {
    console.log("change user password failed");
  }
}

function getChangeUserPassword(formPayload) {
  return Axios.post(apiUrl + "promobuddy/changeuserpassword", formPayload);
}
