/* eslint-disable */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import cookie from "react-cookies";
import OwlCarousel from "react-owl-carousel2";
import "../../common/css/owl.carousel.css";
import "react-tabs/style/react-tabs.css";
import banner2 from "../../common/images/banner2.jpg";
import banner3 from "../../common/images/banner3.jpg";
import banner4 from "../../common/images/banner4.jpg";
import shopbag from "../../common/images/shopbag.png";
import promoicon from "../../common/images/promo-icon.png";
import cashbackicon from "../../common/images/cashback-icon.png";
import evouchericon from "../../common/images/evoucher-icon.png";
import promobanner from "../../common/images/home-left.jpg";
import promobanner1 from "../../common/images/home-right.jpg";
import heart from "../../common/images/heart.svg";
import mega from "../../common/images/mega-phone.png";
import whitearrow from "../../common/images/white-right-arrow.svg";

import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import {
  genetateURL,
  loadCashackImage,
  loadPromoImage,
  loadVoucherImage,
  stripslashes,
  showAlert,
} from "../Helpers/SettingHelper";

import {
  GET_PROMOTIONLIST,
  GET_VOUCHERLIST,
  GET_CASHBACKLIST,
  GET_FAVOURITE,
} from "../../actions";

var Parser = require("html-react-parser");
var qs = require("qs");
var settingsBanner = {
  items: 1,
  loop: true,
  dots: true,
  autoplay: true,
  autoplayTimeout: 4000,
  autoplayHoverPause: false,
};
var settingspromo = {
  items: 5,
  dots: true,

  margin: 15,
  responsive: {
    0: {
      margin: 5,
      items: 2,
    },
    400: {
      items: 2,
    },
    600: {
      items: 3,
    },
    1200: {
      items: 5,
    },
  },
};
var settingscashback = {
  items: 5,
  dots: true,
  margin: 15,
  responsive: {
    0: {
      margin: 5,
      items: 2,
    },
    400: {
      items: 2,
    },
    600: {
      items: 3,
    },
    1200: {
      items: 5,
    },
  },
};
var settingsvouchers = {
  items: 5,
  dots: true,
  margin: 15,
  responsive: {
    0: {
      margin: 5,
      items: 2,
    },
    400: {
      items: 2,
    },
    600: {
      items: 3,
    },
    1200: {
      items: 5,
    },
  },
};
class Home extends Component {
  constructor(props) {
    super(props);
    var UserId = "";
    if (
      cookie.load("UserId") !== "" &&
      typeof cookie.load("UserId") !== undefined &&
      typeof cookie.load("UserId") !== "undefined"
    ) {
      UserId = cookie.load("UserId");
    }
    this.state = {
      UserId: UserId,
      promotions: [],
      displayPromo: "",
      promodetails: "",
      vouchers: [],
      displayVouchers: "",
      voucherdetails: "",
      cashback: [],
      displayCashback: "",
      cashbackdetails: "",
      addfavloading: "",
      orderingType: "Delivery",
      orderingtab: "orderby",
    };
    this.props.getPromotionList();
    this.props.getVoucherList();
    this.props.getCashbackList();
  }
  componentDidMount() {
    if (
      cookie.load("openbusinesslogin") !== "" &&
      typeof cookie.load("openbusinesslogin") !== undefined &&
      typeof cookie.load("openbusinesslogin") !== "undefined"
    ) {
      cookie.remove("openbusinesslogin", { path: "/" });
      $.magnificPopup.open({
        items: {
          src: "#bus-login-popup",
        },
        type: "inline",
      });
    }
  }
  componentWillReceiveProps(PropsData) {
    if (this.state.promotions !== PropsData.promotionlist) {
      this.setState({ promotions: PropsData.promotionlist }, function () {
        this.displayPromotions();
      });
    }
    if (this.state.vouchers !== PropsData.voucherlist) {
      this.setState({ vouchers: PropsData.voucherlist }, function () {
        this.displayVouchers();
      });
    }
    if (this.state.cashback !== PropsData.cashbacklist) {
      this.setState({ cashback: PropsData.cashbacklist }, function () {
        this.displayCashback();
      });
    }

    if (this.state.addfavloading === "loading") {
      if (PropsData.favourite !== undefined) {
        this.showMessage(PropsData.favourite[0]);
      }
    }
  }

  showMessage(response) {
    this.setState({ addfavloading: "" });
    if (response.status === "ok") {
      var result = response.result_set;
      if (result.addremovetype === "A") {
        $(
          "#" +
            result.favourite_type +
            "_" +
            result.list_type +
            "_" +
            result.listindex +
            " .like"
        ).addClass("active");
      } else {
        $(
          "#" +
            result.favourite_type +
            "_" +
            result.list_type +
            "_" +
            result.listindex +
            " .like"
        ).removeClass("active");
      }
      this.props.getPromotionList();
      this.props.getVoucherList();
      this.props.getCashbackList();
    } else {
      if (response.form_error) {
        showAlert("Error", response.form_error);
      } else {
        showAlert("Error", response.message);
      }
      $.magnificPopup.open({
        items: {
          src: ".alert_popup",
        },
        type: "inline",
      });
    }
  }

  displayPromotions() {
    var displayPromo = "";
    if (this.state.promotions.length > 0) {
      displayPromo = this.state.promotions.map((item, index) => {
        var availability =
          item.availability_name !== "" && item.availability_name !== null
            ? item.availability_name.split(",")
            : [];
        return (
          <div key={index} id={"P_l_" + index}>
            <div className="pro-main">
              <div className="pro-main-img">
                {cookie.load("logintype") !== "business" && (
                  <a
                    href={void 0}
                    className={item.favourite === "Y" ? "like active" : "like"}
                    onClick={this.addRemoveFavourite.bind(
                      this,
                      item.promotion_id,
                      "P",
                      index,
                      "l",
                      item.promotion_server
                    )}
                  >
                    {" "}
                    <img src={heart} alt="" />{" "}
                  </a>
                )}
                <img
                  src={loadPromoImage(
                    item.promotion_server,
                    item.host_url,
                    item.promotion_image
                  )}
                />
              </div>
              <div className="pro-main-content">
                <h5 className="pmc-name">
                  <a
                    href={genetateURL(
                      item.promotion_server,
                      item.client_site_url,
                      item.client_app_id
                    )}
                    target="_blank"
                  >
                    {item.client_name}
                  </a>
                </h5>
                <h3 className="pmc-title">{item.promotion_name}</h3>
                <div className="pro-main-option">
                  {availability.length > 0 &&
                    availability.map((avail, index1) => {
                      return <span key={index1}>{avail}</span>;
                    })}
                </div>
                {item.promotion_end !== "" && item.promotion_end !== null && (
                  <div className="pmc-valid">
                    Valid till {item.promotion_end}
                  </div>
                )}
                <div className="pmc-button">
                  {" "}
                  <a
                    href={void 0}
                    className="button"
                    onClick={this.openPromoDetail.bind(this, item)}
                  >
                    Use Code
                  </a>{" "}
                </div>
              </div>
            </div>
          </div>
        );
      });
    }
    this.setState({ displayPromo: displayPromo });
  }
  openPromoDetail(promodetails) {
    this.setState({ promodetails: promodetails }, function () {
      $.magnificPopup.open({
        items: {
          src: "#promodetails-popup",
        },
        type: "inline",
      });
    });
  }
  copyPromo() {
    var copyText = document.getElementById("promo_code");
    /* Select the text field */
    copyText.select();
    copyText.setSelectionRange(0, 99999); /* For mobile devices */
    /* Copy the text inside the text field */
    navigator.clipboard.writeText(copyText.value);

    $(".copied-code-msg").fadeIn();
    setTimeout(function () {
      $(".copied-code-msg").fadeOut();
    }, 3000);
  }

  displayVouchers() {
    var displayVoucher = "";
    if (this.state.vouchers.length > 0) {
      displayVoucher = this.state.vouchers.map((item, index) => {
        var availability =
          item.availability_name !== "" && item.availability_name !== null
            ? item.availability_name.split(",")
            : [];
        return (
          <div key={index} id={"V_l_" + index}>
            <div className="pro-main">
              <div className="pro-main-img">
                {cookie.load("logintype") !== "business" && (
                  <a
                    href={void 0}
                    className={item.favourite === "Y" ? "like active" : "like"}
                    onClick={this.addRemoveFavourite.bind(
                      this,
                      item.product_primary_id,
                      "V",
                      index,
                      "l",
                      item.promotion_server
                    )}
                  >
                    {" "}
                    <img src={heart} alt="" />{" "}
                  </a>
                )}
                <img
                  src={loadVoucherImage(
                    item.promotion_server,
                    item.host_url,
                    item.product_thumbnail
                  )}
                />
              </div>
              <div className="pro-main-content">
                <h5 className="pmc-name">
                  <a
                    href={genetateURL(
                      item.promotion_server,
                      item.client_site_url,
                      item.client_app_id
                    )}
                    target="_blank"
                  >
                    {item.client_name}
                  </a>
                </h5>
                <h3 className="pmc-title">
                  {item.product_alias !== ""
                    ? stripslashes(item.product_alias)
                    : stripslashes(item.product_name)}
                </h3>
                <div className="pro-main-option">
                  {availability.length > 0 &&
                    availability.map((avail, index1) => {
                      return <span key={index1}>{avail}</span>;
                    })}
                </div>
                <div className="pmc-valid">
                  {item.product_voucher_expiry_date !== "" &&
                    item.product_voucher_expiry_date !== null && (
                      <div className="pmc-valid">
                        Valid till {item.product_voucher_expiry_date}
                      </div>
                    )}
                </div>
                <div className="pmc-button">
                  {" "}
                  <a
                    href={void 0}
                    className="button"
                    onClick={this.openVoucherDetail.bind(this, item)}
                  >
                    View
                  </a>{" "}
                </div>
              </div>
            </div>
          </div>
        );
      });
    }
    this.setState({ displayVouchers: displayVoucher });
  }

  openVoucherDetail(voucherdetails) {
    this.setState({ voucherdetails: voucherdetails }, function () {
      $.magnificPopup.open({
        items: {
          src: "#voucherdetails-popup",
        },
        type: "inline",
      });
    });
  }

  displayCashback() {
    var displayCashback = "";
    if (this.state.cashback.length > 0) {
      displayCashback = this.state.cashback.map((item1, index) => {
        var item = item1.client_cashback[0];
        return (
          <div key={index} id={"C_l_" + index}>
            <div className="pro-main">
              <div className="pro-main-img">
                {cookie.load("logintype") !== "business" && (
                  <a
                    href={void 0}
                    className={item1.favourite === "Y" ? "like active" : "like"}
                    onClick={this.addRemoveFavourite.bind(
                      this,
                      item1.client_id,
                      "C",
                      index,
                      "l",
                      item1.promotion_server
                    )}
                  >
                    {" "}
                    <img src={heart} alt="" />{" "}
                  </a>
                )}
                <img
                  src={loadCashackImage(
                    item1.promotion_server,
                    item1.host_url,
                    item.setting_chopchop_cashback_image,
                    "big"
                  )}
                />
              </div>
              <div className="pro-main-content">
                <h5 className="pmc-name">
                  {console.log(item1, "item1item1")}
                  <a
                    href={genetateURL(
                      item1.promotion_server,
                      item1.client_site_url,
                      item1.client_app_id
                    )}
                    target="_blank"
                  >
                    {item1.client_name}
                  </a>
                </h5>
                <h3 className="pmc-title">
                  {item.setting_chopchop_cashback_title}
                </h3>

                {item.setting_chopchop_cashback_description !== "" &&
                  item.setting_chopchop_cashback_description !== null && (
                    <p>{Parser(item.setting_chopchop_cashback_description)}</p>
                  )}

                {item.setting_chopchop_cashback_valid !== "" &&
                  item.setting_chopchop_cashback_valid !== null && (
                    <div className="pmc-valid">
                      Valid till {item.setting_chopchop_cashback_valid}
                    </div>
                  )}
                <div className="pmc-button">
                  {" "}
                  <a
                    href={void 0}
                    onClick={this.openCashbackDetail.bind(this, item1)}
                    className="button"
                  >
                    View
                  </a>{" "}
                </div>
              </div>
            </div>
          </div>
        );
      });
    }
    this.setState({ displayCashback: displayCashback });
  }
  openCashbackDetail(cashbackdetails) {
    this.setState({ cashbackdetails: cashbackdetails }, function () {
      $.magnificPopup.open({
        items: {
          src: "#cashback-popup",
        },
        type: "inline",
      });
    });
  }
  addRemoveFavourite(type_id, type, listindex, list_type, favourite_server) {
    if (this.state.UserId !== "") {
      var postObject = {
        chopchop_customer_id: this.state.UserId,
        favourite_type_id: type_id,
        favourite_type: type,
        favourite_server: favourite_server,
        listindex: listindex,
        list_type: list_type,
      };
      this.setState({ addfavloading: "loading" });
      this.props.getFavouritelist(qs.stringify(postObject));
    } else {
      $.magnificPopup.open({
        items: {
          src: "#cust-login-popup",
        },
        type: "inline",
      });
    }
  }
  handleSelectChange = (event) => {
    this.setState({ orderingType: event.target.value });
  };
  searchRestarent() {
    this.props.history.push(
      "/restaurants/" + this.state.orderingtab + "/" + this.state.orderingType
    );
  }
  selectTab(orderingtab, event) {
    event.preventDefault();
    this.setState({ orderingtab: orderingtab });
  }
  openRegister(event) {
    event.preventDefault();
    if (this.state.UserId !== "") {
      if (
        cookie.load("logintype") !== "" &&
        typeof cookie.load("logintype") !== undefined &&
        typeof cookie.load("logintype") !== "undefined"
      ) {
        if (cookie.load("logintype") !== "customer") {
          this.props.history.push("/business-info");
        } else {
          $.magnificPopup.open({
            items: {
              src: "#warning-login-popup",
            },
            type: "inline",
          });
        }
      }
    } else {
      $.magnificPopup.open({
        items: {
          src: "#bus-login-popup",
        },
        type: "inline",
      });
    }
  }
  render() {
    return (
      <>
        <Header {...this.props} />
        <section className="banner rel">
          <OwlCarousel options={settingsBanner}>
            <div>
              <img src={banner2} />
            </div>
            <div>
              <img src={banner3} />
            </div>
            <div>
              <img src={banner4} />
            </div>
          </OwlCarousel>
          <div className="float-form">
            <div className="container">
              <div className="grey-form">
                <div className="tabs-nav">
                  <ul>
                    <li className="active">
                      <a
                        href="#tab1"
                        onClick={this.selectTab.bind(this, "orderby")}
                      >
                        <img src={shopbag} /> Order Now
                      </a>
                    </li>
                    <li>
                      <a
                        href="#tab1"
                        onClick={this.selectTab.bind(this, "promotions")}
                      >
                        <img src={promoicon} /> Promotions
                      </a>
                    </li>
                    <li>
                      <a
                        href="#tab1"
                        onClick={this.selectTab.bind(this, "cashback")}
                      >
                        <img src={cashbackicon} /> Cash Back
                      </a>
                    </li>
                    <li>
                      <a
                        href="#tab1"
                        onClick={this.selectTab.bind(this, "vouchers")}
                      >
                        <img src={evouchericon} /> E Vouchers
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="tabs-content">
                  <div id="tab1">
                    <div className="tabtype-select">
                      <div className="tabtype-select-box">
                        <label>Ordering Type</label>
                        <select onChange={this.handleSelectChange}>
                          <option value="Delivery">Delivery</option>
                          <option value="Pickup">Pickup</option>
                        </select>
                      </div>
                      <div className="tabtype-select-button">
                        <a
                          href={void 0}
                          onClick={this.searchRestarent.bind(this)}
                          className="button search-button"
                        >
                          Search
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="skip">
                <Link to={"/restaurants"}>Skip & View All</Link>
              </div>
            </div>
          </div>
        </section>
        <section className="promo-banner">
          <div className="container">
            <div className="promo-img">
              <a href="https://www.ninjaos.com" target="_blank">
                {" "}
                <img src={promobanner} />{" "}
              </a>{" "}
            </div>
            <div className="promo-img">
              <a href={void 0} onClick={this.openRegister.bind(this)}>
                {" "}
                <img src={promobanner1} />{" "}
              </a>{" "}
            </div>
          </div>
        </section>
        <section className="latest-promo home-carsouel rel ">
          <div className="container">
            <div className="home-carsouel-title">
              <div className="title-filter">
                <img src={promoicon} />
                <h2>
                  <span>Latest</span> Promotions
                </h2>
              </div>
              <div className="title-filter"></div>
            </div>
            <div>
              {this.state.displayPromo !== "" && (
                <OwlCarousel options={settingspromo}>
                  {this.state.displayPromo}
                </OwlCarousel>
              )}
            </div>
            <div className="home-bottom-link">
              <Link to={"/restaurants/promotions"}>
                <strong>View</strong> More
              </Link>
            </div>
          </div>
        </section>

        <section className="latest-cash home-carsouel rel ">
          <div className="container">
            <div className="home-carsouel-title">
              <div className="title-filter">
                <img src={cashbackicon} />
                <h2>
                  <span>Latest</span> Cashback
                </h2>
              </div>
              <div className="title-filter"></div>
            </div>
            <div>
              <OwlCarousel options={settingscashback}>
                {this.state.displayCashback}
              </OwlCarousel>
            </div>
            <div className="home-bottom-link">
              <Link to={"/restaurants/cashback"}>
                <strong>View</strong> More
              </Link>
            </div>
          </div>
        </section>

        <section className="latest-voch home-carsouel rel ">
          <div className="container">
            <div className="home-carsouel-title">
              <div className="title-filter">
                <img src={evouchericon} />
                <h2>
                  <span>Latest</span> E Vouchers
                </h2>
              </div>
              <div className="title-filter"></div>
            </div>
            <div>
              {this.state.displayVouchers !== "" && (
                <OwlCarousel options={settingsvouchers}>
                  {this.state.displayVouchers}
                </OwlCarousel>
              )}
            </div>
            <div className="home-bottom-link">
              <Link to={"/restaurants/vouchers"}>
                <strong>View</strong> More
              </Link>
            </div>
          </div>
        </section>
        <Footer {...this.props} />
        {/* View Promotion Popup - start */}
        {this.state.promodetails !== "" && (
          <div
            id="promodetails-popup"
            className="white-popup mfp-hide popup_sec detail-popup"
          >
            <div className="cashback-image" id={"P_d_0"}>
              {cookie.load("logintype") !== "business" && (
                <a
                  href={void 0}
                  className={
                    this.state.promodetails.favourite === "Y"
                      ? "like active"
                      : "like"
                  }
                  onClick={this.addRemoveFavourite.bind(
                    this,
                    this.state.promodetails.promotion_id,
                    "P",
                    0,
                    "d",
                    this.state.promodetails.promotion_server
                  )}
                >
                  {" "}
                  <img src={heart} alt="" />{" "}
                </a>
              )}
              <img
                src={loadPromoImage(
                  this.state.promodetails.promotion_server,
                  this.state.promodetails.host_url,
                  this.state.promodetails.promotion_image,
                  "big"
                )}
              />
            </div>
            <div className="cashback-main-content">
              <div className="cashback-main-top">
                <div className="cashback-main-top-left">
                  <img src={mega} alt="" />
                  <h5 className="pmc-name">
                    <a
                      href={genetateURL(
                        this.state.promodetails.promotion_server,
                        this.state.promodetails.client_site_url,
                        this.state.promodetails.client_app_id
                      )}
                      target="_blank"
                    >
                      {this.state.promodetails.client_name}
                    </a>
                    <div className="pro-main-option">
                      {this.state.promodetails.availability_name !== "" &&
                        this.state.promodetails.availability_name
                          .split(",")
                          .map((avail, index1) => {
                            return <span key={index1}>{avail}</span>;
                          })}
                    </div>
                  </h5>
                  <h3 className="pmc-title">
                    {this.state.promodetails.promotion_title !== ""
                      ? stripslashes(this.state.promodetails.promotion_title)
                      : this.state.promodetails.promotion_name !== ""
                      ? stripslashes(this.state.promodetails.promotion_name)
                      : ""}
                  </h3>
                </div>
                {this.state.promodetails.promotion_end !== "" &&
                  this.state.promodetails.promotion_end !== null && (
                    <div className="pmc-valid">
                      Valid till {this.state.promodetails.promotion_end}
                    </div>
                  )}
              </div>
              {this.state.promodetails.promotion_long_desc !== "" &&
                this.state.promodetails.promotion_long_desc !== null && (
                  <div className="cashback-desc">
                    {Parser(this.state.promodetails.promotion_long_desc)}
                  </div>
                )}
              <div className="alert_body">
                <input
                  className="input-copy-code"
                  type="text"
                  id="promo_code"
                  alt="images"
                  value={this.state.promodetails.promotion_name}
                />
                <button className="button" onClick={this.copyPromo.bind(this)}>
                  Copy Code
                </button>
                <span className="copied-code-msg" style={{ display: "none" }}>
                  <div className="alert alert-warning fade show" role="alert">
                    {" "}
                    Copied! Promo Code.
                  </div>
                </span>
              </div>
              <a
                href={genetateURL(
                  this.state.promodetails.promotion_server,
                  this.state.promodetails.client_site_url,
                  this.state.promodetails.client_app_id
                )}
                target="_blank"
                className="button red-btn arrow-fill-btn"
              >
                GO TO ORDERING SITE AND PLACE ORDER{" "}
                <img src={whitearrow} alt="" />
              </a>
            </div>
          </div>
        )}
        {this.state.cashbackdetails !== "" && (
          <div
            id="cashback-popup"
            className="white-popup mfp-hide popup_sec detail-popup"
          >
            <div className="cashback-image" id={"C_d_0"}>
              {cookie.load("logintype") !== "business" && (
                <a
                  href={void 0}
                  className={
                    this.state.promodetails.favourite === "Y"
                      ? "like active"
                      : "like"
                  }
                  onClick={this.addRemoveFavourite.bind(
                    this,
                    this.state.cashbackdetails.client_id,
                    "C",
                    0,
                    "d",
                    this.state.cashbackdetails.promotion_server
                  )}
                >
                  {" "}
                  <img src={heart} alt="" />{" "}
                </a>
              )}
              <img
                src={loadCashackImage(
                  this.state.cashbackdetails.promotion_server,
                  this.state.cashbackdetails.host_url,
                  this.state.cashbackdetails.client_cashback[0]
                    .setting_chopchop_cashback_image,
                  "big"
                )}
              />
            </div>
            <div className="cashback-main-content">
              <div className="cashback-main-top">
                <div className="cashback-main-top-left">
                  <img src={mega} alt="" />
                  <h5 className="pmc-name">
                    <a
                      href={genetateURL(
                        this.state.cashbackdetails.promotion_server,
                        this.state.cashbackdetails.client_site_url,
                        this.state.cashbackdetails.client_app_id
                      )}
                      target="_blank"
                    >
                      {this.state.cashbackdetails.client_name}
                    </a>
                  </h5>
                  <h3 className="pmc-title">
                    {this.state.cashbackdetails.client_cashback[0]
                      .setting_chopchop_cashback_title !== ""
                      ? stripslashes(
                          this.state.cashbackdetails.client_cashback[0]
                            .setting_chopchop_cashback_title
                        )
                      : ""}
                  </h3>
                </div>
                {this.state.cashbackdetails.client_cashback[0]
                  .setting_chopchop_cashback_valid !== "" &&
                  this.state.cashbackdetails.client_cashback[0]
                    .setting_chopchop_cashback_valid !== null && (
                    <div className="pmc-valid">
                      Valid till{" "}
                      {
                        this.state.cashbackdetails.client_cashback[0]
                          .setting_chopchop_cashback_valid
                      }
                    </div>
                  )}
              </div>
              {this.state.cashbackdetails.client_cashback[0]
                .setting_chopchop_cashback_long_description !== "" &&
                this.state.cashbackdetails.client_cashback[0]
                  .setting_chopchop_cashback_long_description !== null && (
                  <div className="cashback-desc">
                    {Parser(
                      this.state.cashbackdetails.client_cashback[0]
                        .setting_chopchop_cashback_long_description
                    )}
                  </div>
                )}
              <a
                href={genetateURL(
                  this.state.cashbackdetails.promotion_server,
                  this.state.cashbackdetails.client_site_url,
                  this.state.cashbackdetails.client_app_id
                )}
                target="_blank"
                className="button red-btn arrow-fill-btn"
              >
                VISIT WEBSITE <img src={whitearrow} alt="" />
              </a>
            </div>
          </div>
        )}
        {this.state.voucherdetails !== "" && (
          <div
            id="voucherdetails-popup"
            className="white-popup mfp-hide popup_sec detail-popup"
          >
            <div className="cashback-image" id={"C_d_0"}>
              {cookie.load("logintype") !== "business" && (
                <a
                  href={void 0}
                  className={
                    this.state.promodetails.favourite === "Y"
                      ? "like active"
                      : "like"
                  }
                  onClick={this.addRemoveFavourite.bind(
                    this,
                    this.state.voucherdetails.product_primary_id,
                    "V",
                    0,
                    "d",
                    this.state.voucherdetails.promotion_server
                  )}
                >
                  {" "}
                  <img src={heart} alt="" />{" "}
                </a>
              )}
              <img
                src={loadVoucherImage(
                  this.state.voucherdetails.promotion_server,
                  this.state.voucherdetails.host_url,
                  this.state.voucherdetails.product_thumbnail,
                  "big"
                )}
              />
            </div>
            <div className="cashback-main-content">
              <div className="cashback-main-top">
                <div className="cashback-main-top-left">
                  <img src={mega} alt="" />
                  <h5 className="pmc-name">
                    <a
                      href={genetateURL(
                        this.state.voucherdetails.promotion_server,
                        this.state.voucherdetails.client_site_url,
                        this.state.voucherdetails.client_app_id
                      )}
                      target="_blank"
                    >
                      {this.state.voucherdetails.client_name}
                    </a>
                    <div className="pro-main-option">
                      {this.state.voucherdetails.availability_name !== "" &&
                        this.state.voucherdetails.availability_name
                          .split(",")
                          .map((avail, index1) => {
                            return <span key={index1}>{avail}</span>;
                          })}
                    </div>
                  </h5>
                  <h3 className="pmc-title">
                    {this.state.voucherdetails.product_alias !== ""
                      ? stripslashes(this.state.voucherdetails.product_alias)
                      : stripslashes(this.state.voucherdetails.product_name)}
                  </h3>
                </div>
                {this.state.voucherdetails.product_voucher_expiry_date !== "" &&
                  this.state.voucherdetails.product_voucher_expiry_date !==
                    null && (
                    <div className="pmc-valid">
                      Valid till{" "}
                      {this.state.voucherdetails.product_voucher_expiry_date}
                    </div>
                  )}
              </div>
              {this.state.voucherdetails.product_long_description !== "" &&
                this.state.voucherdetails.product_long_description !== null && (
                  <div className="cashback-desc">
                    {Parser(this.state.voucherdetails.product_long_description)}
                  </div>
                )}
              <a
                href={genetateURL(
                  this.state.voucherdetails.promotion_server,
                  this.state.voucherdetails.client_site_url,
                  this.state.voucherdetails.client_app_id
                )}
                target="_blank"
                className="button red-btn arrow-fill-btn"
              >
                VISIT WEBSITE <img src={whitearrow} alt="" />
              </a>
            </div>
          </div>
        )}
        {/* View Promotion Popup - end */}
      </>
    );
  }
}

const mapStateTopProps = (state) => {
  var promotionlist = Array();
  if (Object.keys(state.promotionlist).length > 0) {
    if (state.promotionlist[0].status === "ok") {
      promotionlist = state.promotionlist[0].result_set;
    }
  }

  var voucherlist = Array();
  if (Object.keys(state.voucherlist).length > 0) {
    if (state.voucherlist[0].status === "ok") {
      voucherlist = state.voucherlist[0].result_set;
    }
  }

  var cashbacklist = Array();
  if (Object.keys(state.cashbacklist).length > 0) {
    if (state.cashbacklist[0].status === "ok") {
      cashbacklist = state.cashbacklist[0].result_set;
    }
  }
  return {
    promotionlist: promotionlist,
    voucherlist: voucherlist,
    cashbacklist: cashbacklist,
    favourite: state.favourite,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getPromotionList: () => {
      dispatch({ type: GET_PROMOTIONLIST });
    },
    getVoucherList: () => {
      dispatch({ type: GET_VOUCHERLIST });
    },
    getCashbackList: () => {
      dispatch({ type: GET_CASHBACKLIST });
    },
    getFavouritelist: (formPayload) => {
      dispatch({ type: GET_FAVOURITE, formPayload });
    },
  };
};

export default connect(mapStateTopProps, mapDispatchToProps)(Home);
