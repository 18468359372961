/* eslint-disable */
import React, { Component } from "react";

import { withRouter } from "react-router-dom";
import { appId, apiUrl } from "../Helpers/Config";
import { validated } from "react-custom-validation";
import validator from "validator";

const isEmpty = (value) => (value === "" ? "This field is required." : null);
const isEmail = (email) =>
  validator.isEmail(email) ? null : "This is not a valid email.";

const minLength = (password, length) =>
  password.length >= length ? null : "Password must be at least 6 characters.";

const areSame = (password, rePassword) =>
  password === rePassword ? null : "Password do not match.";

const isChecked = (terms) =>
  terms ? null : "Please accept terms and conditions";

function validationConfigSignup(props) {
  const {
    firstname,
    lastname,
    email,
    password,
    rePassword,
    mobile,
    terms,
    pdpa_consent,
  } = props.fields;

  return {
    fields: [
      "firstname",
      "lastname",
      "email",
      "password",
      "rePassword",
      "mobile",
      "terms",
      "pdpa_consent",
    ],

    validations: {
      firstname: [[isEmpty, firstname]],
      lastname: [[isEmpty, lastname]],
      email: [
        [isEmail, email],
        /* [isUnique, email]*/
      ],
      password: [[minLength, password, 6]],
      rePassword: {
        rules: [[areSame, password, rePassword]],
        fields: ["password", "rePassword"],
      },
      mobile: [
        [isEmpty, mobile],
        [isMobile, mobile],
        /* [isPhonenumerExist, mobile]*/
      ],
      terms: [[isChecked, terms, 1]],
    },
  };
}

const phonenumberPattern = /^[0-9]{6,14}$/;
const isMobile = (mobile) =>
  mobile.match(phonenumberPattern) ? null : "please enter valid Mobile Number.";

const isUnique = (email) => {
  var qs = require("qs");
  var postObject = {
    app_id: appId,
    type: "web",
    customer_email: email,
  };

  axios
    .post(apiUrl + "customer/email_exist", qs.stringify(postObject))
    .then((response) => {
      if (response.data.status === "ok") {
        document.getElementById("spn-email-error").innerHTML = "";
      } else {
        document.getElementById("spn-email-error").innerHTML =
          '<span class="error">Email already exists</span>';
      }
    });
};

const isPhonenumerExist = (mobile) => {
  var qs = require("qs");
  var postObject = {
    app_id: appId,
    type: "web",
    customer_phone: mobile,
  };
  axios
    .post(apiUrl + "customer/phoneno_exist", qs.stringify(postObject))
    .then((response) => {
      if (response.data.status === "ok") {
        document.getElementById("spn-mobile-error").innerHTML = "";
      } else {
        document.getElementById("spn-mobile-error").innerHTML =
          '<span class="error">Mobile number already exists</span>';
      }
    });
};

class Signup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      step: 1,
      errMsgRestaurantName: "",
    };
  }

  closepopup(path) {
    const { history } = this.props;
    history.push(path);
    $.magnificPopup.close();
  }
  gotoNexStep(step) {
    console.log(this.props.fields, "this.props");
    var frmfield = this.props.fields;
    var error = 0;
    if (step === 2) {
      if (frmfield.restaurantname === "") {
        error++;
        this.setState({
          errMsgRestaurantName: (
            <span className="error">This field is required.</span>
          ),
        });
      } else {
        this.setState({ errMsgRestaurantName: "" });
      }
      if (frmfield.postal_code === "") {
        error++;
        this.setState({
          errMsgPostalCode: (
            <span className="error">This field is required.</span>
          ),
        });
      } else {
        this.setState({ errMsgPostalCode: "" });
      }
      if (frmfield.address === "") {
        error++;
        this.setState({
          errMsgAddress: <span className="error">This field is required.</span>,
        });
      } else {
        this.setState({ errMsgAddress: "" });
      }
    }
    if (error === 0) {
      this.setState({ step: step });
    }
  }
  render() {
    const { fields, onChange, onValid, onInvalid, $field, $validation } =
      this.props;
    const spanStyle = {
      clear: "both",
    };
    let errMsgFirstName,
      errMsgLastName,
      errMsgEmail,
      errMsgPassword,
      errMsgrePassword,
      errMsgMobile,
      errMsgTerms;

    if ($validation.firstname.error.reason !== undefined) {
      errMsgFirstName = $validation.firstname.show && (
        <span className="error">{$validation.firstname.error.reason}</span>
      );
    }
    if ($validation.lastname.error.reason !== undefined) {
      errMsgLastName = $validation.lastname.show && (
        <span className="error">{$validation.lastname.error.reason}</span>
      );
    }

    if ($validation.email.error.reason !== undefined) {
      errMsgEmail = $validation.email.show && (
        <span className="error">{$validation.email.error.reason}</span>
      );
    }
    if ($validation.password.error.reason !== undefined) {
      errMsgPassword = $validation.password.show && (
        <span className="error">{$validation.password.error.reason}</span>
      );
    }
    if ($validation.rePassword.error.reason !== undefined) {
      errMsgrePassword = $validation.rePassword.show && (
        <span className="error">{$validation.rePassword.error.reason}</span>
      );
    }
    if ($validation.mobile.error.reason !== undefined) {
      errMsgMobile = $validation.mobile.show && (
        <span className="error">{$validation.mobile.error.reason}</span>
      );
    }
    if ($validation.terms.error.reason !== undefined) {
      errMsgTerms = $validation.terms.show && (
        <span className="error">{$validation.terms.error.reason}</span>
      );
    }

    return (
      <div className="popup-body">
        {this.state.step === 1 && (
          <>
            <div className="form-group">
              <div className="focus-out">
                <input
                  type="text"
                  placeholder="Restaurant Name"
                  className="form-control input-focus"
                  value={fields.restaurantname}
                  {...$field("restaurantname", (e) => {
                    this.setState({ errMsgRestaurantName: "" });
                    onChange("restaurantname", e.target.value);
                  })}
                />
                {this.state.errMsgRestaurantName}
              </div>
            </div>
            <div className="form-one-next">
              <p>Your Restaurant Address</p>
            </div>
            <div className="form-group">
              <div className="focus-out">
                <input
                  type="number"
                  placeholder="Postal Code"
                  className="form-control input-focus"
                  value={fields.postal_code}
                  {...$field("postal_code", (e) => {
                    this.setState({ errMsgPostalCode: "" });
                    onChange("postal_code", e.target.value);
                  })}
                />
                {this.state.errMsgPostalCode}
              </div>
            </div>
            <div className="form-group">
              <div className="focus-out">
                <input
                  type="text"
                  placeholder="Address"
                  className="form-control input-focus"
                  value={fields.address}
                  {...$field("address", (e) => {
                    this.setState({ errMsgAddress: "" });
                    onChange("address", e.target.value);
                  })}
                />
                {this.state.errMsgAddress}
              </div>
            </div>
            <div className="form-group">
              <div className="login_pop_sub">
                <button
                  type="button"
                  className="btn btn_black btn_minwid signup_submit"
                  onClick={this.gotoNexStep.bind(this, 2)}
                >
                  Next
                </button>
              </div>
            </div>
          </>
        )}
        {this.state.step === 2 && (
          <>
            <div className="form-group">
              <div className="focus-out">
                <input
                  type="text"
                  placeholder="First Name"
                  className="form-control input-focus"
                  value={fields.firstname}
                  {...$field("firstname", (e) =>
                    onChange("firstname", e.target.value)
                  )}
                />
                {errMsgFirstName}
              </div>
            </div>
            <div className="form-group">
              <div className="focus-out">
                <input
                  type="text"
                  placeholder="Last Name"
                  className="form-control input-focus"
                  value={fields.lastname}
                  {...$field("lastname", (e) =>
                    onChange("lastname", e.target.value)
                  )}
                />
                {errMsgLastName}
              </div>
            </div>
            <div className="form-group">
              <div className="focus-out">
                <input
                  type="tel"
                  placeholder="Mobile Number"
                  className="form-control input-focus"
                  pattern="\d*"
                  value={fields.mobile}
                  maxLength="8"
                  {...$field("mobile", (e) =>
                    onChange("mobile", e.target.value)
                  )}
                />
                <div id="spn-mobile-error">{errMsgMobile}</div>
              </div>
            </div>
            <div className="form-group">
              <div className="focus-out">
                <input
                  type="text"
                  placeholder="Email Address"
                  className="form-control input-focus"
                  value={fields.email}
                  {...$field("email", (e) => onChange("email", e.target.value))}
                />
                <div id="spn-email-error">{errMsgEmail}</div>
              </div>
            </div>
            <div className="form-group">
              <div className="focus-out">
                <input
                  type="password"
                  placeholder="Password"
                  className="form-control input-focus"
                  value={fields.password}
                  {...$field("password", (e) =>
                    onChange("password", e.target.value)
                  )}
                />
                {errMsgPassword}
              </div>
            </div>
            <div className="form-group">
              <div className="focus-out">
                <input
                  type="password"
                  placeholder="Confirm Password"
                  className="form-control input-focus"
                  value={fields.rePassword}
                  {...$field("rePassword", (e) =>
                    onChange("rePassword", e.target.value)
                  )}
                />
                {errMsgrePassword}
              </div>
            </div>
            <div className="form-group">
              <div className="custom_checkbox custom_checkbox_content">
                <input
                  type="checkbox"
                  id="terms"
                  checked={fields.terms}
                  {...$field(
                    "terms",
                    (e) => onChange("terms", e.target.value),
                    null,
                    false
                  )}
                />{" "}
                <span></span>
                <div className="sign_reg">
                  <p>
                    Yes, I have read and agree to the Canadian 2 For 1 Pizza
                    &nbsp;
                    <a
                      className="sign_reg_uline"
                      href="/terms-and-conditions"
                      target="_blank"
                    >
                      Terms &amp; Conditions.
                    </a>
                  </p>
                </div>
              </div>
              {errMsgTerms}
            </div>

            <div className="form-group">
              <div className="login_pop_sub">
                <button
                  type="button"
                  className="btn btn_black btn_minwid signup_submit"
                  onClick={this.gotoNexStep.bind(this, 1)}
                >
                  Go Back
                </button>
                <button
                  type="button"
                  className="btn btn_black btn_minwid signup_submit"
                  onClick={(e) => {
                    e.preventDefault();
                    this.props.$submit(onValid, onInvalid);
                  }}
                >
                  Submit
                </button>
              </div>
            </div>
          </>
        )}
        <div className="form-group-11">
          <div className="controls single-link">
            <a href="#bus-login-popup" className="open-popup-link">
              Back to login
            </a>
          </div>
        </div>
      </div>
    );
  }
}
Signup = validated(validationConfigSignup)(Signup);

export default withRouter(Signup);
