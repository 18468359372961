/* eslint-disable */
import { takeEvery, call, put } from "redux-saga/effects";
import { GET_CUSTOMERDETAILS, SET_CUSTOMERDETAILS } from "../actions";
import { apiUrl } from "../components/Helpers/Config";
import Axios from "axios";
import cookie from "react-cookies";

export const watchGetCustomerDetails = function* () {
  yield takeEvery(GET_CUSTOMERDETAILS, workerGetCustomerDetails);
};

function* workerGetCustomerDetails() {
  try {
    const uri =
      apiUrl +
      "promobuddy/getChopchopCustomer?chopchop_customer_id=" +
      cookie.load("UserId");
    const result = yield call(Axios.get, uri);
    var resultArr = [];
    resultArr.push(result.data);
    yield put({ type: SET_CUSTOMERDETAILS, value: resultArr });
  } catch {
    console.log("Get Customer Details Failed");
  }
}
