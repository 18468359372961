/* eslint-disable */
import { takeEvery, call, put } from "redux-saga/effects";
import { GET_FORGETBUS_PASSWORD, SET_FORGETBUS_PASSWORD } from "../actions";
import { apiUrl } from "../components/Helpers/Config";
import Axios from "axios";
export const watchGetForgetBusPassword = function* () {
  yield takeEvery(GET_FORGETBUS_PASSWORD, workerGetForgetBusPassword);
};

function* workerGetForgetBusPassword({ formPayload }) {
  try {
    console.log(formPayload);
    const result = yield call(getForgetBusPassword, formPayload);
    var resultArr = [];
    resultArr.push(result.data);
    yield put({ type: SET_FORGETBUS_PASSWORD, value: resultArr });
  } catch {
    console.log("failed");
  }
}

function getForgetBusPassword(formPayload) {
  return Axios.post(apiUrl + "promobuddy/forgotuserpassword", formPayload);
}
