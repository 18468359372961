import React, { Component } from "react";
import cookie from "react-cookies";

class Logout extends Component {
  componentWillMount() {
    cookie.remove("UserId", { path: "/" });
    cookie.remove("UserMobile", { path: "/" });
    cookie.remove("UserFname", { path: "/" });
    cookie.remove("logintype", { path: "/" });
    cookie.remove("UserEmail", { path: "/" });
    cookie.remove("UserLname", { path: "/" });
    this.props.history.push("/");
  }

  render() {
    return <h1 className="loading-text">Logging out...</h1>;
  }
}

export default Logout;
