import { SET_FAVOURITE } from "../actions";

const favourite = (state = [], action) => {
  switch (action.type) {
    case SET_FAVOURITE:
      return [...action.value];
    default:
      return state;
  }
};

export default favourite;
