import { SET_TAGLIST } from "../actions";

const taglist = (state = [], action) => {
  switch (action.type) {
    case SET_TAGLIST:
      return [...action.value];
    default:
      return state;
  }
};

export default taglist;
