/* eslint-disable */
import React, { Component } from "react";
import { connect } from "react-redux";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import cookie from "react-cookies";
import { format } from "date-fns";
import heart from "../../common/images/heart.svg";
import clock from "../../common/images/clock.png";
import bag from "../../common/images/bag-small.png";
import wpromo from "../../common/images/white-promo.png";
import wcart from "../../common/images/shop-cart.png";
import wloyal from "../../common/images/loyal-icon.png";
import filter from "../../common/images/filter-purple.svg";
import closep from "../../common/images/close-purple.svg";
import noimagethumb from "../../common/images/placeholder.jpg";
import whitearrow from "../../common/images/white-right-arrow.svg";
import couponbig from "../../common/images/coupon-big.png";
import mega from "../../common/images/mega-phone.png";
import {
  genetateURL,
  loadCashackImage,
  loadPromoImage,
  loadVoucherImage,
  stripslashes,
  dateconvert,
  timeconvert,
} from "../Helpers/SettingHelper";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import { GET_RESTAURANTLIST, GET_TAGLIST, GET_FAVOURITE } from "../../actions";
var Parser = require("html-react-parser");

var qs = require("qs");
class Listpromo extends Component {
  constructor(props) {
    super(props);
    var currentPage = this.props.match.path;
    var tagfilterlook = [];
    if (currentPage === "/restaurants/promotions") {
      tagfilterlook.push("promotion");
    } else if (currentPage === "/restaurants/cashback") {
      tagfilterlook.push("cashback");
    } else if (currentPage === "/restaurants/vouchers") {
      tagfilterlook.push("evoucher");
    }
    var orderingType = "";
    if (
      this.props.match.params !== "" &&
      typeof this.props.match.params !== undefined &&
      typeof this.props.match.params !== "undefined"
    ) {
      if (
        this.props.match.params.orderType !== "" &&
        typeof this.props.match.params.orderType !== undefined &&
        typeof this.props.match.params.orderType !== "undefined"
      ) {
        orderingType = this.props.match.params.orderType;
      }
    }
    var UserId = "";
    if (
      cookie.load("UserId") !== "" &&
      typeof cookie.load("UserId") !== undefined &&
      typeof cookie.load("UserId") !== "undefined"
    ) {
      UserId = cookie.load("UserId");
    }
    this.state = {
      UserId: UserId,
      restaurant: [],
      displayrestaurant: "",
      taglist: [],
      displayTagList: "",
      tagfilter: [],
      tagfilterlook: tagfilterlook,
      orderingType: orderingType,
      totalRestaurant: "",
      clientdetails: "",
      currentPage: this.props.match.path,
      addfavloading: "",
      promodetails: "",
      voucherdetails: "",
      orderingdate: "",
      orderingtime: "",
      errororderingType: "",
      errororderingdate: "",
      errororderingtime: "",
    };
    this.props.getRestaurantList();
    this.props.getTagList();
    this.handleChangeDate = this.handleChangeDate.bind(this);
    this.handleChangeTime = this.handleChangeTime.bind(this);
  }
  componentDidMount() {}
  componentWillReceiveProps(PropsData) {
    if (this.state.restaurant !== PropsData.restaurantlist) {
      this.setState({ restaurant: PropsData.restaurantlist }, function () {
        $("#dvLoading").fadeOut();
        this.displayRestaurant();
      });
    }
    if (this.state.taglist !== PropsData.taglist) {
      this.setState({ taglist: PropsData.taglist }, function () {
        this.displayTagList();
      });
    }
    if (this.state.addfavloading === "loading") {
      if (PropsData.favourite !== undefined && PropsData.favourite.length > 0) {
        console.log(PropsData.favourite, "PropsData.favourite");
        this.showMessage(PropsData.favourite[0]);
      }
    }
  }
  showMessage(response) {
    this.setState({ addfavloading: "" });
    if (response.status === "ok") {
      var result = response.result_set;
      if (result.addremovetype === "A") {
        $(
          "#" +
            result.favourite_type +
            "_" +
            result.list_type +
            "_" +
            result.listindex +
            " .like"
        ).addClass("active");
      } else {
        $(
          "#" +
            result.favourite_type +
            "_" +
            result.list_type +
            "_" +
            result.listindex +
            " .like"
        ).removeClass("active");
      }
      this.props.getRestaurantList();
    } else {
      if (response.form_error) {
        showAlert("Error", response.form_error);
      } else {
        showAlert("Error", response.message);
      }
      $.magnificPopup.open({
        items: {
          src: ".alert_popup",
        },
        type: "inline",
      });
    }
  }

  displayRestaurant() {
    var displayRestaurant = "";
    var totalRestaurant = 0;
    if (this.state.restaurant.length > 0) {
      displayRestaurant = this.state.restaurant.map((item, index) => {
        var availability =
          item.availability_name !== "" && item.availability_name !== null
            ? item.availability_name.split(",")
            : [];
        var checkFilter = this.checkFilter(item);
        if (checkFilter === true) {
          totalRestaurant++;
          return (
            <li key={index} id={"R_l_" + index}>
              <div className="outlet-img">
                {cookie.load("logintype") !== "business" && (
                  <a
                    href={void 0}
                    className={item.favourite === "Y" ? "like active" : "like"}
                    onClick={this.addRemoveFavourite.bind(
                      this,
                      item.client_id,
                      "R",
                      index,
                      "l",
                      item.restaurant_server
                    )}
                  >
                    {" "}
                    <img src={heart} alt="" />{" "}
                  </a>
                )}
                <img
                  src={
                    item.client_logo !== "" && item.client_logo !== null
                      ? item.host_url +
                        "media/dev_team/chopchop/restaurants/" +
                        item.client_logo
                      : noimagethumb
                  }
                  alt=""
                />
              </div>
              <div className="outlet-content">
                <div className="pro-main-option">
                  {availability.length > 0 &&
                    availability.map((avail, index1) => {
                      return <span key={index1}>{avail}</span>;
                    })}
                </div>
                <div className="oc-title">
                  <h2>
                    <a
                      href={genetateURL(
                        item.restaurant_server,
                        item.client_site_url,
                        item.client_app_id
                      )}
                      target="_blank"
                    >
                      {item.client_name}
                    </a>
                  </h2>
                  <ul>
                    {item.earlist_delivery !== "" &&
                      item.earlist_delivery !== null && (
                        <li>
                          <img src={clock} alt="" />{" "}
                          <span>
                            Earlist Delivery : {item.earlist_delivery}
                          </span>
                        </li>
                      )}
                    {parseFloat(item.promotion_amount) > 0 && (
                      <li>
                        <img src={bag} alt="" />{" "}
                        <span>
                          Min Order : $
                          {parseFloat(item.promotion_amount).toFixed(2)}
                        </span>
                      </li>
                    )}
                    {/* <li><img src={pin} alt="" /> <span>Direction : 13.3 KM Away</span></li> */}
                  </ul>
                </div>
                <div className="oc-desc">
                  {item.client_about_company !== "" &&
                    item.client_about_company !== null &&
                    Parser(item.client_about_company)}
                </div>

                <div className="oc-buttons">
                  {item.client_cashback.length > 0 && (
                    <a
                      href={void 0}
                      className="button red-btn"
                      onClick={this.openDetail.bind(this, item, "cashback")}
                    >
                      <img src={wloyal} alt="Cash Back" />
                      Cash Back
                    </a>
                  )}

                  {item.client_promotions.length > 0 && (
                    <a
                      href={void 0}
                      onClick={this.openDetail.bind(this, item, "promotion")}
                      className="button green-btn"
                    >
                      <img src={wpromo} alt="View Promo" />
                      View Promo
                    </a>
                  )}
                  <a
                    href={genetateURL(
                      item.restaurant_server,
                      item.client_site_url,
                      item.client_app_id
                    )}
                    target="_blank"
                    className="button order-btn"
                  >
                    <img src={wcart} alt="Order Now" />
                    Order Now
                  </a>
                </div>
              </div>
            </li>
          );
        }
      });
    }

    if (totalRestaurant === 0) {
      displayRestaurant = (
        <li className="no_restaurants">
          <h3>No Restaurants Found</h3>
          <p>
            There are no restaurants matching your selected filters. <br />
            Try widening your search to see what’s available!
          </p>
        </li>
      );
    }

    this.setState({
      displayRestaurant: displayRestaurant,
      totalRestaurant: totalRestaurant,
    });
  }

  checkFilter(restaurant) {
    var result = true;
    if (this.state.tagfilterlook.length > 0) {
      if (this.state.tagfilterlook.indexOf("promotion") >= 0) {
        if (
          restaurant.client_promotions !== "" &&
          restaurant.client_promotions !== null
        ) {
          if (restaurant.client_promotions.length === 0) {
            if (result === true) {
              result = false;
            }
          }
        }
      }

      if (this.state.tagfilterlook.indexOf("cashback") >= 0) {
        if (
          restaurant.client_cashback !== "" &&
          restaurant.client_cashback !== null
        ) {
          if (restaurant.client_cashback.length === 0) {
            if (result === true) {
              result = false;
            }
          }
        }
      }
      if (this.state.tagfilterlook.indexOf("evoucher") >= 0) {
        if (
          restaurant.client_vouchers !== "" &&
          restaurant.client_vouchers !== null
        ) {
          if (restaurant.client_vouchers.length === 0) {
            if (result === true) {
              result = false;
            }
          }
        }
      }
    }

    if (this.state.tagfilter.length > 0 && result === true) {
      var checkTag = 0;
      this.state.tagfilter.map((item) => {
        if (restaurant.client_tag_list.indexOf(item) > 0) {
          checkTag++;
        }
      });
      if (checkTag === 0) {
        result = false;
      }
    }
    if (
      this.state.orderingType !== "" &&
      result === true &&
      restaurant.availability_name !== null
    ) {
      if (restaurant.availability_name.indexOf(this.state.orderingType) < 0) {
        result = false;
      }
    }

    return result;
  }

  openDetail(clientdetails, tabtype) {
    this.setState(
      { clientdetails: clientdetails, tabtype: tabtype },
      function () {
        setTimeout(function () {
          $.magnificPopup.open({
            items: {
              src: "#details-popup",
            },
            type: "inline",
          });
        }, 100);
      }
    );
  }
  displayTagList() {
    var displayTagList = "";
    if (this.state.taglist.length > 0) {
      displayTagList = this.state.taglist.map((item, index) => {
        return (
          <div className="filter-option" key={index}>
            <h5>{item.tag_categories_title}</h5>
            {item.tag.length > 0 && (
              <ul>
                {item.tag.map((item1, index1) => {
                  return (
                    <li key={index1}>
                      <input
                        type="checkbox"
                        className="tag_filter"
                        onChange={this.handlechange.bind(
                          this,
                          item1.ol_tag_name
                        )}
                      />
                      <label>{item1.ol_tag_name}</label>
                    </li>
                  );
                })}
              </ul>
            )}
          </div>
        );
      });
    }

    this.setState({ displayTagList: displayTagList });
  }

  handlechange(tag) {
    var tagfilter = this.state.tagfilter;
    if (tagfilter.indexOf(tag) >= 0) {
      const index = tagfilter.indexOf(tag);
      tagfilter.splice(index, 1);
    } else {
      tagfilter.push(tag);
    }
    this.setState({ tagfilter: tagfilter }, function () {
      this.displayRestaurant();
    });
  }
  handlelookchange(tag) {
    var tagfilterlook = this.state.tagfilterlook;
    if (tagfilterlook.indexOf(tag) >= 0) {
      const index = tagfilterlook.indexOf(tag);
      tagfilterlook.splice(index, 1);
    } else {
      tagfilterlook.push(tag);
    }
    this.setState({ tagfilterlook: tagfilterlook }, function () {
      this.displayRestaurant();
    });
  }

  displayPromotions(client) {
    var displayPromo = "";
    if (client.client_promotions.length > 0) {
      displayPromo = client.client_promotions.map((item, index) => {
        var availability =
          item.availability_name !== "" && item.availability_name !== null
            ? item.availability_name.split(",")
            : [];
        return (
          <li key={index} id={"P_l_" + index}>
            <div className="pro-main">
              <div className="pro-main-img">
                {cookie.load("logintype") !== "business" && (
                  <a
                    href={void 0}
                    className={item.favourite === "Y" ? "like active" : "like"}
                    onClick={this.addRemoveFavourite.bind(
                      this,
                      item.promotion_id,
                      "P",
                      index,
                      "l",
                      client.restaurant_server
                    )}
                  >
                    {" "}
                    <img src={heart} alt="" />{" "}
                  </a>
                )}
                <img
                  src={loadPromoImage(
                    client.restaurant_server,
                    client.host_url,
                    item.promotion_image,
                    "thumb"
                  )}
                />
              </div>
              <div className="pro-main-content">
                <h5 className="pmc-name">
                  <a
                    href={genetateURL(
                      client.restaurant_server,
                      client.client_site_url,
                      client.client_app_id
                    )}
                    target="_blank"
                  >
                    {client.client_name}
                  </a>
                </h5>
                <h3 className="pmc-title">{item.promotion_name}</h3>
                <div className="pro-main-option">
                  {availability.length > 0 &&
                    availability.map((avail, index1) => {
                      return <span key={index1}>{avail}</span>;
                    })}
                </div>
                {item.promotion_end !== "" && item.promotion_end !== null && (
                  <div className="pmc-valid">
                    Valid till {item.promotion_end}
                  </div>
                )}
                <div className="pmc-button">
                  {" "}
                  <a
                    href={void 0}
                    className="button"
                    onClick={this.openPromoDetail.bind(this, item)}
                  >
                    Use Code
                  </a>{" "}
                </div>
              </div>
            </div>
          </li>
        );
      });
    }
    setTimeout(function () {
      if ($(".mCustomScrollbar").length > 0) {
        $(".mCustomScrollbar").mCustomScrollbar();
      }
    }, 10);
    return displayPromo;
  }

  displayVouchers(client) {
    var displayVoucher = "";
    if (client.client_vouchers.length > 0) {
      displayVoucher = client.client_vouchers.map((item, index) => {
        var availability =
          item.availability_name !== "" && item.availability_name !== null
            ? item.availability_name.split(",")
            : [];
        return (
          <li key={index}>
            <div className="pro-main">
              <div className="pro-main-img">
                <a href="#" className="like">
                  {" "}
                  <img src={heart} alt="" />{" "}
                </a>
                <img
                  src={loadVoucherImage(
                    client.restaurant_server,
                    item.host_url,
                    item.product_thumbnail,
                    "thumb"
                  )}
                />
              </div>
              <div className="pro-main-content">
                <h5 className="pmc-name">
                  <a
                    href={genetateURL(
                      client.restaurant,
                      client.client_site_url,
                      client.client_app_id
                    )}
                    target="_blank"
                  >
                    {client.client_name}
                  </a>
                </h5>
                <h3 className="pmc-title">
                  {item.product_alias !== ""
                    ? stripslashes(item.product_alias)
                    : stripslashes(item.product_name)}
                </h3>
                <div className="pro-main-option">
                  {availability.length > 0 &&
                    availability.map((avail, index1) => {
                      return <span key={index1}>{avail}</span>;
                    })}
                </div>
                {item.product_voucher_expiry_date !== "" &&
                  item.product_voucher_expiry_date !== null && (
                    <div className="pmc-valid">
                      Valid till {item.product_voucher_expiry_date}
                    </div>
                  )}
                <div className="pmc-button">
                  {" "}
                  <a
                    href={void 0}
                    className="button"
                    onClick={this.openVoucherDetail.bind(this, item)}
                  >
                    View
                  </a>{" "}
                </div>
              </div>
            </div>
          </li>
        );
      });
    }
    setTimeout(function () {
      if ($(".mCustomScrollbar").length > 0) {
        $(".mCustomScrollbar").mCustomScrollbar();
      }
    }, 10);
    return displayVoucher;
  }

  displayCashback(client) {
    var displayVoucher = "";
    if (client.client_cashback.length > 0) {
      displayVoucher = client.client_cashback.map((item, index) => {
        return (
          <li key={index} id={"C_l_" + index}>
            <div className="cashback-image">
              {cookie.load("logintype") !== "business" && (
                <a
                  href={void 0}
                  className={item.favourite === "Y" ? "like active" : "like"}
                  onClick={this.addRemoveFavourite.bind(
                    this,
                    client.client_id,
                    "C",
                    index,
                    "l",
                    client.restaurant_server
                  )}
                >
                  {" "}
                  <img src={heart} alt="" />{" "}
                </a>
              )}
              <img
                src={loadCashackImage(
                  client.restaurant_server,
                  item.host_url,
                  item.setting_chopchop_cashback_image,
                  "thumb"
                )}
              />
            </div>
            <div className="cashback-main-content">
              <div className="cashback-main-top">
                <div className="cashback-main-top-left">
                  <img src={couponbig} alt="" />
                  <h5 className="pmc-name">
                    <a
                      href={genetateURL(
                        client.restaurant,
                        client.client_site_url,
                        client.client_app_id
                      )}
                      target="_blank"
                    >
                      {client.client_name}
                    </a>
                  </h5>
                  <h3 className="pmc-title">
                    {item.setting_chopchop_cashback_title !== ""
                      ? stripslashes(item.setting_chopchop_cashback_title)
                      : ""}
                  </h3>
                </div>
                {item.setting_chopchop_cashback_valid !== "" &&
                  item.setting_chopchop_cashback_valid !== null && (
                    <div className="pmc-valid">
                      Valid till {item.setting_chopchop_cashback_valid}
                    </div>
                  )}
              </div>
              {item.setting_chopchop_cashback_description !== "" && (
                <div className="cashback-desc">
                  {Parser(item.setting_chopchop_cashback_description)}
                </div>
              )}
              {item.setting_chopchop_cashback_link !== "" && (
                <a
                  href={item.setting_chopchop_cashback_link}
                  target="_blank"
                  className="button red-btn arrow-fill-btn"
                >
                  Visit Website <img src={whitearrow} alt="" />
                </a>
              )}
            </div>
          </li>
        );
      });
    }

    return displayVoucher;
  }

  gotoTab(tabtype) {
    this.setState({ tabtype: tabtype });
  }

  addRemoveFavourite(type_id, type, listindex, list_type, favourite_server) {
    if (this.state.UserId !== "") {
      var postObject = {
        chopchop_customer_id: this.state.UserId,
        favourite_type_id: type_id,
        favourite_type: type,
        favourite_server: favourite_server,
        listindex: listindex,
        list_type: list_type,
      };
      this.setState({ addfavloading: "loading" });
      this.props.getFavourite(qs.stringify(postObject));
    } else {
      $.magnificPopup.open({
        items: {
          src: "#cust-login-popup",
        },
        type: "inline",
      });
    }
  }

  openPromoDetail(promodetails) {
    this.setState({ promodetails: promodetails }, function () {
      $.magnificPopup.close();
      $.magnificPopup.open({
        items: {
          src: "#promodetails-popup",
        },
        type: "inline",
      });
    });
  }
  copyPromo() {
    var copyText = document.getElementById("promo_code");
    /* Select the text field */
    copyText.select();
    copyText.setSelectionRange(0, 99999); /* For mobile devices */
    /* Copy the text inside the text field */
    navigator.clipboard.writeText(copyText.value);

    $(".copied-code-msg").fadeIn();
    setTimeout(function () {
      $(".copied-code-msg").fadeOut();
    }, 3000);
  }
  openVoucherDetail(voucherdetails) {
    this.setState({ voucherdetails: voucherdetails }, function () {
      $.magnificPopup.open({
        items: {
          src: "#voucherdetails-popup",
        },
        type: "inline",
      });
    });
  }
  handleSelectChange = (event) => {
    this.setState({ orderingType: event.target.value, errororderingType: "" });
  };
  searchRestarent() {
    var error = 0;
    if (this.state.orderingType === "") {
      error++;
      this.setState({ errororderingType: "Y" });
    } else {
      this.setState({ errororderingType: "" });
    }
    if (this.state.orderingtime !== "") {
      if (this.state.orderingdate === "") {
        error++;
        this.setState({ errororderingdate: "Y" });
      } else {
        this.setState({ errororderingdate: "" });
      }
    }
    if (error === 0) {
      if (this.state.orderingdate !== "") {
        var orderingfilter = "";
        if (this.state.orderingType !== "") {
          orderingfilter += "&orderingType=" + this.state.orderingType;
        }
        if (this.state.orderingdate !== "") {
          orderingfilter +=
            "&orderingdate=" + dateconvert(this.state.orderingdate);
        }
        if (this.state.orderingtime !== "") {
          orderingfilter +=
            "&orderingtime=" + timeconvert(this.state.orderingtime);
        }
        console.log(orderingfilter, "orderingfilter");
        this.props.getRestaurantList(orderingfilter);
      } else {
        this.displayRestaurant();
      }
    }
  }
  handleChangeDate(datevalue) {
    this.setState({ orderingdate: datevalue });
  }
  handleChangeTime(datevalue) {
    this.setState({ orderingtime: datevalue });
  }

  render() {
    return (
      <>
        <Header {...this.props} />
        <section>
          <div className="container">
            <div className="popup-filter">
              <div className="popup-filter-header">
                <h3>
                  Filter by
                  <a className="popup-filter-close">
                    <img src={closep} alt="" />
                  </a>
                </h3>
              </div>

              <div className="filter-option">
                <h5>Looking For</h5>
                <ul>
                  <li>
                    <input
                      type="checkbox"
                      className="tag_filter"
                      onChange={this.handlelookchange.bind(this, "ordernow")}
                    />
                    <label>Order Now</label>
                  </li>
                  <li>
                    <input
                      type="checkbox"
                      className="tag_filter"
                      defaultChecked={
                        this.state.currentPage === "/restaurants/promotions"
                          ? true
                          : false
                      }
                      onChange={this.handlelookchange.bind(this, "promotion")}
                    />
                    <label>Promotions</label>
                  </li>
                  <li>
                    <input
                      type="checkbox"
                      className="tag_filter"
                      defaultChecked={
                        this.state.currentPage === "/restaurants/cashback"
                          ? true
                          : false
                      }
                      onChange={this.handlelookchange.bind(this, "cashback")}
                    />
                    <label>Cash Back</label>
                  </li>
                  <li>
                    <input
                      type="checkbox"
                      className="tag_filter"
                      defaultChecked={
                        this.state.currentPage === "/restaurants/vouchers"
                          ? true
                          : false
                      }
                      onChange={this.handlelookchange.bind(this, "evoucher")}
                    />
                    <label>E Vouchers</label>
                  </li>
                </ul>
              </div>
              {this.state.displayTagList}
              <div className="button-bottom">
                <a className="button" title="Clear all">
                  Clear all
                </a>
                <a className="button" title="Apply">
                  Apply
                </a>
              </div>
            </div>
            <div className="outlet-listing-inner">
              <div className="outlet-listing-lhs">
                <h4>
                  <span>Filter by</span>
                </h4>
                <div className="filter-option">
                  <h5>Looking For</h5>
                  <ul>
                    <li>
                      <input
                        type="checkbox"
                        className="tag_filter"
                        onChange={this.handlelookchange.bind(this, "ordernow")}
                      />
                      <label>Order Now</label>
                    </li>
                    <li>
                      <input
                        type="checkbox"
                        className="tag_filter"
                        defaultChecked={
                          this.state.currentPage === "/restaurants/promotions"
                            ? true
                            : false
                        }
                        onChange={this.handlelookchange.bind(this, "promotion")}
                      />
                      <label>Promotions</label>
                    </li>
                    <li>
                      <input
                        type="checkbox"
                        className="tag_filter"
                        defaultChecked={
                          this.state.currentPage === "/restaurants/cashback"
                            ? true
                            : false
                        }
                        onChange={this.handlelookchange.bind(this, "cashback")}
                      />
                      <label>Cash Back</label>
                    </li>
                    <li>
                      <input
                        type="checkbox"
                        className="tag_filter"
                        defaultChecked={
                          this.state.currentPage === "/restaurants/vouchers"
                            ? true
                            : false
                        }
                        onChange={this.handlelookchange.bind(this, "evoucher")}
                      />
                      <label>E Vouchers</label>
                    </li>
                  </ul>
                </div>
                {this.state.displayTagList}
              </div>

              <div className="outlet-listing-rhs">
                <div className="outlet-listing-filter">
                  <div className="odtype-select-top">
                    <div className="odtype-select">
                      <label>Ordering Type</label>
                      <select
                        value={this.state.orderingType}
                        onChange={this.handleSelectChange}
                      >
                        <option value="">Select Ordering Type</option>
                        <option value="Delivery">Delivery</option>
                        <option value="Pickup">Pickup</option>
                      </select>
                      {this.state.errororderingType !== "" && (
                        <span className="error">This field is required.</span>
                      )}
                    </div>
                    <div className="odtype-select-one">
                      <DatePicker
                        placeholderText="Date"
                        minDate={new Date()}
                        onChange={this.handleChangeDate}
                        selected={this.state.orderingdate}
                        dateFormat="MMMM d, yyyy"
                        className="form-control ordr-datetime-cls"
                      />
                      {this.state.errororderingdate !== "" && (
                        <span className="error">This field is required.</span>
                      )}
                    </div>
                    <div className="odtype-select-two">
                      <DatePicker
                        placeholderText="Time"
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={5}
                        selected={this.state.orderingtime}
                        timeCaption="Time"
                        dateFormat="h:mm aa"
                        onChange={this.handleChangeTime}
                        className="form-control ordr-datetime-cls"
                      />
                    </div>
                    <div className="odtype-select-btn res-osb">
                      <a
                        href={void 0}
                        className="search-btn"
                        onClick={this.searchRestarent.bind(this)}
                      >
                        Search
                      </a>
                    </div>
                  </div>
                  <div className="odtype-select-btn desk-osb">
                    <a
                      href={void 0}
                      className="search-btn"
                      onClick={this.searchRestarent.bind(this)}
                    >
                      Search
                    </a>
                  </div>
                </div>
                <div className="showing-header">
                  <h3>
                    Showing <strong>{this.state.totalRestaurant}</strong>{" "}
                    Restaurants
                  </h3>
                  <span className="filter-icon">
                    <img src={filter} alt="" />
                  </span>
                </div>
                <ul>{this.state.displayRestaurant}</ul>
              </div>
            </div>
          </div>
        </section>
        <Footer {...this.props} />
        {/* View Promotion Popup - start */}
        <div id="details-popup" className="white-popup mfp-hide popup_sec">
          {this.state.clientdetails !== "" && (
            <div className="custom_alert">
              <div className="custom_alertin">
                <div className="oc-buttons">
                  {this.state.clientdetails.client_promotions !== null &&
                    this.state.clientdetails.client_promotions.length > 0 && (
                      <a
                        href={void 0}
                        onClick={this.gotoTab.bind(this, "promotion")}
                        className={
                          this.state.tabtype === "promotion"
                            ? "button red-btn"
                            : "button"
                        }
                      >
                        <img src={wloyal} alt="Promotions" />
                        Promotions
                      </a>
                    )}
                  {console.log(
                    this.state.clientdetails.client_cashback,
                    "this.state.clientdetails.client_cashback"
                  )}
                  {this.state.clientdetails.client_cashback !== null &&
                    this.state.clientdetails.client_cashback.length > 0 && (
                      <a
                        href={void 0}
                        onClick={this.gotoTab.bind(this, "cashback")}
                        className={
                          this.state.tabtype === "cashback"
                            ? "button red-btn"
                            : "button"
                        }
                      >
                        <img src={wpromo} alt="Cash Back" />
                        Cash Back
                      </a>
                    )}
                  {this.state.clientdetails.client_vouchers !== null &&
                    this.state.clientdetails.client_vouchers.length > 0 && (
                      <a
                        href={void 0}
                        onClick={this.gotoTab.bind(this, "vouchers")}
                        className={
                          this.state.tabtype === "vouchers"
                            ? "button red-btn"
                            : "button"
                        }
                      >
                        <img src={wcart} alt="E Vouchers" />E Vouchers
                      </a>
                    )}
                </div>
                <div className="client-tab">
                  {this.state.tabtype === "promotion" && (
                    <div className="promo-tab mCustomScrollbar">
                      <ul>
                        {this.displayPromotions(this.state.clientdetails)}
                      </ul>
                    </div>
                  )}
                  {this.state.tabtype === "cashback" && (
                    <div className="cashback-tab">
                      <ul>{this.displayCashback(this.state.clientdetails)}</ul>
                    </div>
                  )}
                  {this.state.tabtype === "vouchers" && (
                    <div className="vouchers-tab mCustomScrollbar">
                      <ul>{this.displayVouchers(this.state.clientdetails)}</ul>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>

        {this.state.promodetails !== "" && (
          <div
            id="promodetails-popup"
            className="white-popup mfp-hide popup_sec detail-popup"
          >
            <div className="cashback-image" id={"P_d_0"}>
              {cookie.load("logintype") !== "business" && (
                <a
                  href={void 0}
                  className={
                    this.state.promodetails.favourite === "Y"
                      ? "like active"
                      : "like"
                  }
                  onClick={this.addRemoveFavourite.bind(
                    this,
                    this.state.promodetails.promotion_id,
                    "P",
                    0,
                    "d",
                    this.state.clientdetails.restaurant_server
                  )}
                >
                  {" "}
                  <img src={heart} alt="" />{" "}
                </a>
              )}
              <img
                src={loadPromoImage(
                  this.state.clientdetails.restaurant_server,
                  this.state.clientdetails.host_url,
                  this.state.promodetails.promotion_image,
                  "big"
                )}
              />
            </div>
            <div className="cashback-main-content">
              <div className="cashback-main-top">
                <div className="cashback-main-top-left">
                  <img src={mega} alt="" />
                  <h5 className="pmc-name">
                    <a
                      href={genetateURL(
                        this.state.clientdetails.restaurant_server,
                        this.state.clientdetails.client_site_url,
                        this.state.clientdetails.client_app_id
                      )}
                      target="_blank"
                    >
                      {this.state.clientdetails.client_name}
                    </a>
                    <div className="pro-main-option">
                      {this.state.promodetails.availability_name !== "" &&
                        this.state.promodetails.availability_name
                          .split(",")
                          .map((avail, index1) => {
                            return <span key={index1}>{avail}</span>;
                          })}
                    </div>
                  </h5>
                  <h3 className="pmc-title">
                    {this.state.promodetails.promotion_title !== ""
                      ? stripslashes(this.state.promodetails.promotion_title)
                      : this.state.promodetails.promotion_name !== ""
                      ? stripslashes(this.state.promodetails.promotion_name)
                      : ""}
                  </h3>
                </div>
                {this.state.promodetails.promotion_end !== "" &&
                  this.state.promodetails.promotion_end !== null && (
                    <div className="pmc-valid">
                      Valid till {this.state.promodetails.promotion_end}
                    </div>
                  )}
              </div>
              {this.state.promodetails.promotion_long_desc !== "" &&
                this.state.promodetails.promotion_long_desc !== null && (
                  <div className="cashback-desc">
                    {Parser(this.state.promodetails.promotion_long_desc)}
                  </div>
                )}
              <div className="alert_body">
                <input
                  className="input-copy-code"
                  type="text"
                  id="promo_code"
                  alt="images"
                  value={this.state.promodetails.promotion_name}
                />
                <button className="button" onClick={this.copyPromo.bind(this)}>
                  Copy Code
                </button>
                <span className="copied-code-msg" style={{ display: "none" }}>
                  <div className="alert alert-warning fade show" role="alert">
                    {" "}
                    Copied! Promo Code.
                  </div>
                </span>
              </div>
              <a
                href={genetateURL(
                  this.state.clientdetails.restaurant_server,
                  this.state.clientdetails.client_site_url,
                  this.state.clientdetails.client_app_id
                )}
                target="_blank"
                className="button red-btn arrow-fill-btn"
              >
                GO TO ORDERING SITE AND PLACE ORDER{" "}
                <img src={whitearrow} alt="" />
              </a>
            </div>
          </div>
        )}
        {this.state.voucherdetails !== "" && (
          <div
            id="voucherdetails-popup"
            className="white-popup mfp-hide popup_sec detail-popup"
          >
            <div className="cashback-image" id={"C_d_0"}>
              {cookie.load("logintype") !== "business" && (
                <a
                  href={void 0}
                  className={
                    this.state.promodetails.favourite === "Y"
                      ? "like active"
                      : "like"
                  }
                  onClick={this.addRemoveFavourite.bind(
                    this,
                    this.state.voucherdetails.product_primary_id,
                    "V",
                    0,
                    "d",
                    this.state.clientdetails.restaurant_server
                  )}
                >
                  {" "}
                  <img src={heart} alt="" />{" "}
                </a>
              )}
              <img
                src={loadVoucherImage(
                  this.state.clientdetails.restaurant_server,
                  this.state.voucherdetails.host_url,
                  this.state.voucherdetails.product_thumbnail,
                  "big"
                )}
              />
            </div>
            <div className="cashback-main-content">
              <div className="cashback-main-top">
                <div className="cashback-main-top-left">
                  <img src={mega} alt="" />
                  <h5 className="pmc-name">
                    <a
                      href={genetateURL(
                        this.state.clientdetails.restaurant_server,
                        this.state.clientdetails.client_site_url,
                        this.state.clientdetails.client_app_id
                      )}
                      target="_blank"
                    >
                      {this.state.clientdetails.client_name}
                    </a>
                    <div className="pro-main-option">
                      {this.state.voucherdetails.availability_name !== "" &&
                        this.state.voucherdetails.availability_name
                          .split(",")
                          .map((avail, index1) => {
                            return <span key={index1}>{avail}</span>;
                          })}
                    </div>
                  </h5>
                  <h3 className="pmc-title">
                    {this.state.voucherdetails.product_alias !== ""
                      ? stripslashes(this.state.voucherdetails.product_alias)
                      : stripslashes(this.state.voucherdetails.product_name)}
                  </h3>
                </div>
                {this.state.voucherdetails.product_voucher_expiry_date !== "" &&
                  this.state.voucherdetails.product_voucher_expiry_date !==
                    null && (
                    <div className="pmc-valid">
                      Valid till{" "}
                      {this.state.voucherdetails.product_voucher_expiry_date}
                    </div>
                  )}
              </div>
              {this.state.voucherdetails.product_long_description !== "" &&
                this.state.voucherdetails.product_long_description !== null && (
                  <div className="cashback-desc">
                    {Parser(this.state.voucherdetails.product_long_description)}
                  </div>
                )}
              <a
                href={genetateURL(
                  this.state.clientdetails.restaurant_server,
                  this.state.clientdetails.client_site_url,
                  this.state.clientdetails.client_app_id
                )}
                target="_blank"
                className="button red-btn arrow-fill-btn"
              >
                VISIT WEBSITE <img src={whitearrow} alt="" />
              </a>
            </div>
          </div>
        )}

        {/* View Promotion Popup - end */}
        {/* <div id="dvLoading" className="dvLoadrCls"></div> */}
      </>
    );
  }
}

const mapStateTopProps = (state) => {
  var restaurantlist = Array();
  var totalRecord = "";
  if (Object.keys(state.restaurantlist).length > 0) {
    if (state.restaurantlist[0].status === "ok") {
      restaurantlist = state.restaurantlist[0].result_set;
      totalRecord = state.restaurantlist[0].total_record;
    } else {
      totalRecord = 0;
    }
  }

  var taglist = Array();
  if (Object.keys(state.taglist).length > 0) {
    if (state.taglist[0].status === "ok") {
      taglist = state.taglist[0].result_set;
    }
  }

  return {
    restaurantlist: restaurantlist,
    totalRecord: totalRecord,
    taglist: taglist,
    favourite: state.favourite,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getRestaurantList: (orderingfilter) => {
      dispatch({ type: GET_RESTAURANTLIST, orderingfilter });
    },
    getTagList: () => {
      dispatch({ type: GET_TAGLIST });
    },
    getFavourite: (formPayload) => {
      dispatch({ type: GET_FAVOURITE, formPayload });
    },
  };
};

export default connect(mapStateTopProps, mapDispatchToProps)(Listpromo);
