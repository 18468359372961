/* eslint-disable */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import update from "immutability-helper";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import OwlCarousel from "react-owl-carousel2";
import "../../common/css/owl.carousel.css";
import cookie from "react-cookies";
import Changepassword from "./Changepassword";
import Changeuserpassword from "./Changeuserpassword";
import { GET_CHANGEPASSWORD, GET_CHANGEUSERPASSWORD } from "../../actions";
import { appId } from "../Helpers/Config";
import { showAlert, showLoader, hideLoader } from "../Helpers/SettingHelper";
var qs = require("qs");
class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activePage: this.props.pageName,
      deliveryTakeaway_orders: "",
      fieldschpassword: {
        oldpassword: "",
        newpassword: "",
        confirmpassword: "",
      },
      fieldsuserchpassword: {
        oldpassword: "",
        newpassword: "",
        confirmpassword: "",
      },
    };
    this.changepassword = this.changepassword.bind(this);
    this.change_user_password = this.change_user_password.bind(this);
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.changepassword !== this.props.changepassword) {
      $(".old_password").val("");
      $(".new_password").val("");
      $(".confirm_password").val("");
      hideLoader("change-pass-sbmt", "class");
      if (nextProps.changepassword[0].status === "ok") {
        showAlert("Success", "Password changed successfully!");
      } else {
        if (nextProps.changepassword[0].form_error) {
          showAlert("Error", nextProps.changepassword[0].form_error);
        } else {
          showAlert("Error", nextProps.changepassword[0].message);
        }
      }
      window.$.magnificPopup.open({
        items: {
          src: ".alert_popup",
        },
        type: "inline",
      });
    }
    if (nextProps.changeuserpassword !== this.props.changeuserpassword) {
      $(".old_user_password").val("");
      $(".new_user_password").val("");
      $(".confirm_user_password").val("");
      hideLoader("change-pass-sbmt", "class");
      if (nextProps.changeuserpassword[0].status === "ok") {
        showAlert("Success", "Password changed successfully!");
      } else {
        if (nextProps.changeuserpassword[0].form_error) {
          showAlert("Error", nextProps.changeuserpassword[0].form_error);
        } else {
          showAlert("Error", nextProps.changeuserpassword[0].message);
        }
      }
      window.$.magnificPopup.open({
        items: {
          src: ".alert_popup",
        },
        type: "inline",
      });
    }
  }

  fieldChange = (field, value) => {
    this.setState(
      update(this.state, { fieldschpassword: { [field]: { $set: value } } })
    );
  };

  /* Change Password */
  changepassword() {
    var old_password = $(".old_password").val();
    var new_password = $(".new_password").val();
    var confirm_password = $(".confirm_password").val();
    var postObject = {
      oldpassword: old_password,
      password: new_password,
      confirmpassword: confirm_password,
      refrence: cookie.load("UserId"),
    };
    showLoader("change-pass-sbmt", "class");
    this.props.getChangePassword(qs.stringify(postObject));
  }

  fieldUserChange = (field, value) => {
    this.setState(
      update(this.state, { fieldschpassword: { [field]: { $set: value } } })
    );
  };

  /* Change User Password */
  change_user_password() {
    var old_password = $(".old_user_password").val();
    var new_password = $(".new_user_password").val();
    var confirm_password = $(".confirm_user_password").val();
    var postObject = {
      oldpassword: old_password,
      password: new_password,
      confirmpassword: confirm_password,
      refrence: cookie.load("UserId"),
    };
    showLoader("change-pass-sbmt", "class");
    this.props.getChangeUserPassword(qs.stringify(postObject));
  }

  custchangepassword() {
    $.magnificPopup.open({
      items: {
        src: "#change-password-popup",
      },
      type: "inline",
    });
  }
  userchangepassword() {
    $.magnificPopup.open({
      items: {
        src: "#change-user-password-popup",
      },
      type: "inline",
    });
  }

  render() {
    var initialSlide = 0;
    if (this.state.activePage === "myorders") {
      initialSlide = 1;
    } else if (this.state.activePage === "rewards") {
      initialSlide = 2;
    } else if (this.state.activePage === "mypromotions") {
      initialSlide = 3;
    }
    var settingsMyAcc = {
      items: 5,
      loop: true,
      dots: true,
      margin: 15,
      responsive: {
        0: {
          items: 1,
        },
        400: {
          items: 2,
        },
        600: {
          items: 3,
        },
        1200: {
          items: 5,
        },
      },
    };
    return (
      <>
        <div className="mbacc_mslidersec mbacc_mslider ">
          <OwlCarousel options={settingsMyAcc}>
            <div
              className={
                this.state.activePage === "my-account"
                  ? "mbacc_mslide active"
                  : "mbacc_mslide"
              }
            >
              <Link to="/my-account" title="My Account">
                <span>Account Details</span>
              </Link>
            </div>
            <div
              className={
                this.state.activePage === "business-info"
                  ? "mbacc_mslide active"
                  : "mbacc_mslide"
              }
            >
              <Link to="/business-info" title="Business Informations">
                <span>Business Informations</span>
              </Link>
            </div>
            <div
              className={
                this.state.activePage === "rewards"
                  ? "mbacc_mslide active"
                  : "mbacc_mslide"
              }
            >
              <Link to="/rewards" title="Rewards">
                <span>Rewards</span>
              </Link>
            </div>
            <div
              className={
                this.state.activePage === "promotions"
                  ? "mbacc_mslide active"
                  : "mbacc_mslide"
              }
            >
              <Link to="/promotions" title="Promotions">
                <span>Promotions</span>
              </Link>
            </div>
            <li
              className={this.state.activePage === "vouchers" ? "active" : ""}
            >
              <Link to="/vouchers" title="Vouchers">
                <span>Vouchers</span>
              </Link>
            </li>
            <div className="mbacc_mslide">
              <a href="#change-password-popup" className="open-popup-link">
                Change Password
              </a>
            </div>
            <div className="mbacc_mslide">
              <a href="/logout">Logout</a>
            </div>
          </OwlCarousel>
        </div>
        <div className="accmenu_sec">
          <div className="setting_menu_list">
            {cookie.load("logintype") === "business" && (
              <ul>
                <li
                  className={
                    this.state.activePage === "my-account" ? "active" : ""
                  }
                >
                  <Link to="/my-account" title="My Account">
                    <span>Account Details</span>
                  </Link>
                </li>
                <li
                  className={
                    this.state.activePage === "business-info" ? "active" : ""
                  }
                >
                  <Link to="/business-info" title="Business Informations">
                    <span>Business Informations</span>
                  </Link>
                </li>

                <li
                  className={
                    this.state.activePage === "rewards" ? "active" : ""
                  }
                >
                  <Link to="/rewards" title="Rewards">
                    <span>Rewards</span>
                  </Link>
                </li>
                <li
                  className={
                    this.state.activePage === "promotions" ? "active" : ""
                  }
                >
                  <Link to="/promotions" title="My Promotions">
                    <span>Promotions</span>
                  </Link>
                </li>
                <li
                  className={
                    this.state.activePage === "vouchers" ? "active" : ""
                  }
                >
                  <Link to="/vouchers" title="Vouchers">
                    <span>Vouchers</span>
                  </Link>
                </li>
                <li>
                  <a
                    href={void 0}
                    onClick={this.userchangepassword.bind(this)}
                    className="open-popup-link"
                  >
                    Change Password
                  </a>
                </li>
                <li>
                  <a href="/logout">Logout</a>
                </li>
              </ul>
            )}
            {cookie.load("logintype") === "customer" && (
              <ul>
                <li
                  className={
                    this.state.activePage === "myaccount" ? "active" : ""
                  }
                >
                  <Link to="/myaccount" title="My Account">
                    <span>Account Details</span>
                  </Link>
                </li>
                <li>
                  <a
                    href={void 0}
                    onClick={this.custchangepassword.bind(this)}
                    className="open-popup-link"
                  >
                    Change Password
                  </a>
                </li>
                <li>
                  <a href="/logout">Logout</a>
                </li>
              </ul>
            )}
          </div>
        </div>
        <div
          id="change-password-popup"
          className="white-popup mfp-hide popup_sec changepw_popup"
        >
          <div className="pop-whole full-login-new">
            <div className="full-login-rhs full-login">
              <div className="full-login-rhs-header">
                <h3 className="title1 text-center">Change Password</h3>
              </div>
              <Changepassword
                fields={this.state.fieldschpassword}
                onChange={this.fieldChange}
                onValid={this.changepassword}
                onInvalid={() => console.log("Form invalid!")}
              />
            </div>
          </div>
        </div>
        <div
          id="change-user-password-popup"
          className="white-popup mfp-hide popup_sec changepw_popup"
        >
          <div className="pop-whole full-login-new">
            <div className="full-login-rhs full-login">
              <div className="full-login-rhs-header">
                <h3 className="title1 text-center">Change Password</h3>
              </div>
              <Changeuserpassword
                fields={this.state.fieldschpassword}
                onChange={this.fieldUserChange}
                onValid={this.change_user_password}
                onInvalid={() => console.log("Form invalid!")}
              />
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    changepassword: state.changepassword,
    changeuserpassword: state.changeuserpassword,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getChangePassword: (formPayload) => {
      dispatch({ type: GET_CHANGEPASSWORD, formPayload });
    },
    getChangeUserPassword: (formPayload) => {
      dispatch({ type: GET_CHANGEUSERPASSWORD, formPayload });
    },
  };
};

Sidebar.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Sidebar)
);
