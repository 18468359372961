/* eslint-disable */

import React from "react";
import { validated } from "react-custom-validation";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Select from "react-select";
import DatePicker from "react-datepicker";
import { format } from "date-fns";
import "react-datepicker/dist/react-datepicker.css";
import noimage from "../../common/images/placeholder.jpg";
import { mediaUrl } from "../Helpers/Config";
import Sidebar from "./Sidebar";
const isEmpty = (value) => (value === "" ? "This field is required." : null);
const phonenumberPattern = /^[0-9]$/;
const isMobile = (mobile) =>
  mobile.match(phonenumberPattern) ? null : "This is not a valid postal code.";
function validationConfig(props) {
  const { title, end_date, voucher_url } = props.fields;
  return {
    fields: ["title", "end_date", "voucher_url"],

    validations: {
      title: [[isEmpty, title]],
      end_date: [[isEmpty, end_date]],
      voucher_url: [[isEmpty, voucher_url]],
    },
  };
}

class Form extends React.Component {
  constructor(props) {
    var Maxdate = new Date();
    Maxdate.setFullYear(Maxdate.getFullYear() - 10);
    super(props);
    this.state = {
      status: "Loading",
      delivery_start: "",
      displayVoucher: "",
      showvoucherfrm: false,
    };
    this.handleChangeDate = this.handleChangeDate.bind(this);
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.showvoucherfrm !== this.state.showvoucherfrm) {
      this.props.sateValChange("showvoucherfrm", nextProps.showvoucherfrm);
      this.setState({ showvoucherfrm: nextProps.showvoucherfrm });
    }
  }
  handleChangeDate(datevalue) {
    var dateval = new Date(datevalue);
    dateval = format(dateval, "h:mm aa");
    this.setState({ end_date: datevalue });
    this.handleChange("end_date", datevalue);
  }

  handleChange = (item, value) => {
    this.props.onChange(item, value);
    this.setState({ ...this.state, [item]: value });
  };

  displayVouchers() {
    var displayVoucher = "";
    if (this.props.vouchers.length > 0) {
      displayVoucher = this.props.vouchers.map((item, index) => {
        var availability =
          item.availability_name !== "" && item.availability_name !== null
            ? item.availability_name.split(",")
            : [];
        return (
          <li key={index}>
            <div className="pro-main">
              <div className="pro-main-img">
                <img
                  src={
                    item.voucher_image !== "" && item.voucher_image !== null
                      ? mediaUrl + "chopchop/vouchers/" + item.voucher_image
                      : noimage
                  }
                />
              </div>
              <div className="pro-main-content">
                <h3 className="pmc-title">{item.voucher_title}</h3>
                <div className="pro-main-option">
                  {availability.length > 0 &&
                    availability.map((avail, index1) => {
                      return <span key={index1}>{avail}</span>;
                    })}
                </div>
                {item.voucher_end !== "" && item.voucher_end !== null && (
                  <div className="pmc-valid">Valid till {item.voucher_end}</div>
                )}
                <div className="pmc-button">
                  {" "}
                  <a
                    href={void 0}
                    className="button button-fill"
                    onClick={this.openvoucherfrm.bind(this, item)}
                  >
                    Edit
                  </a>
                  <a
                    href={void 0}
                    className="button"
                    onClick={this.props.removeVoucher.bind(
                      this,
                      item.voucher_id
                    )}
                  >
                    Delete
                  </a>
                </div>
              </div>
            </div>
          </li>
        );
      });
    }
    return displayVoucher;
  }
  openvoucherfrm(item) {
    if (item !== "") {
      this.setState({ end_date: new Date(item.voucher_end_date) });
      this.props.onEdit(item);
    }
    this.setState({ showvoucherfrm: true }, function () {
      this.props.sateValChange("showvoucherfrm", true);
    });
  }
  closevoucherfrm() {
    this.setState({ showvoucherfrm: false }, function () {
      this.props.sateValChange("showvoucherfrm", false);
    });
  }
  opendDetailImg(detailimage) {
    this.setState({ detailimage: detailimage }, function () {
      $.magnificPopup.open({
        items: {
          src: "#imagedetails-popup",
        },
        type: "inline",
      });
    });
  }

  render() {
    const { fields, onChange, onValid, onInvalid, $field, $validation } =
      this.props;

    let errMsgTitle, errMsgEnddate, errMsgVoucherUrl;
    var end_date = new Date();
    if (this.state.end_date !== "") {
      end_date = this.state.end_date;
    } else if (
      fields.end_date !== "" &&
      fields.end_date !== null &&
      fields.end_date !== "0000-00-00 00:00:00"
    ) {
      end_date = new Date(fields.end_date);
    }
    console.log($validation, "$validation$validation");
    if ($validation.title.error.reason !== undefined) {
      errMsgTitle = $validation.title.show && (
        <span className="error">{$validation.title.error.reason}</span>
      );
    }

    if ($validation.end_date.error.reason !== undefined) {
      errMsgEnddate = $validation.end_date.show && (
        <span className="error">{$validation.end_date.error.reason}</span>
      );
    }
    if ($validation.voucher_url.error.reason !== undefined) {
      errMsgVoucherUrl = $validation.voucher_url.show && (
        <span className="error">{$validation.voucher_url.error.reason}</span>
      );
    }
    return (
      <div className="container-one">
        <div className="tab-content">
          <div className="tab-pane account-tab-section fade in active">
            <div className="tab_mobrow main_tabin">
              <div className="account_sec">
                <Sidebar pageName="vouchers" {...this.props} />
                <div className="accsec_right promo-new-add">
                  {this.state.showvoucherfrm == true && (
                    <form
                      action="#"
                      method="post"
                      encType="multipart/form-data"
                    >
                      <div className="acc-inform">
                        {/* profile-info-div - end */}
                        <div className="profile-info-div">
                          <h4 className="form_grouptt">
                            Your Voucher Information
                          </h4>
                          <div className="form-group half-fg">
                            <div className="row">
                              <div className="col-md-6">
                                <div className="focus-out">
                                  <input
                                    type="text"
                                    placeholder="Voucher Title"
                                    name="title"
                                    className="form-control input-focus"
                                    value={fields.title || ""}
                                    {...$field("title", (e) =>
                                      onChange("title", e.target.value)
                                    )}
                                  />
                                  {errMsgTitle}
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="focus-out">
                                  <Select
                                    placeholder="Select Availability"
                                    closeMenuOnSelect={false}
                                    value={fields.availability}
                                    selectMultiple={true}
                                    isMulti
                                    onChange={onChange.bind(
                                      this,
                                      "availability"
                                    )}
                                    options={[
                                      { value: "Delivery", label: "Delivery" },
                                      { value: "Pickup", label: "Pickup" },
                                    ]}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="form-group half-fg">
                            <div className="row">
                              <div className="col-md-6">
                                <div className="focus-out">
                                  <DatePicker
                                    placeholderText="End Date"
                                    showTimeSelect
                                    onChange={this.handleChangeDate}
                                    className="form-control"
                                    selected={end_date}
                                    dateFormat="MMMM d, yyyy h:mm aa"
                                    minDate={new Date()}
                                  />
                                  {errMsgEnddate}
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="focus-out">
                                  <input
                                    type="file"
                                    name="voucher_image"
                                    accept="image/*"
                                    id="voucher_image"
                                    className="form-control input-focus"
                                  />
                                </div>
                                {fields.voucher_image !== "" &&
                                  fields.voucher_image !== null && (
                                    <a
                                      href={void 0}
                                      onClick={this.opendDetailImg.bind(
                                        this,
                                        fields.voucher_image
                                      )}
                                      className="view-image"
                                    >
                                      View Image
                                    </a>
                                  )}
                              </div>
                            </div>
                          </div>
                          <div className="form-group half-fg">
                            <div className="row">
                              <div className="col-md-6">
                                <div className="focus-out">
                                  <input
                                    type="text"
                                    placeholder="Voucher URL"
                                    name="voucher_url"
                                    value={fields.voucher_url || ""}
                                    {...$field("voucher_url", (e) =>
                                      onChange("voucher_url", e.target.value)
                                    )}
                                    className="form-control input-focus"
                                  />
                                  {errMsgVoucherUrl}
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="focus-out">
                                  <Select
                                    placeholder="Status"
                                    value={fields.status}
                                    onChange={onChange.bind(this, "status")}
                                    options={[
                                      { value: "A", label: "Active" },
                                      { value: "I", label: "Inactive" },
                                    ]}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="form-group full-fg">
                            <div className="row">
                              <div className="col-md-12">
                                <div className="focus-out">
                                  <textarea
                                    type="text"
                                    placeholder="About Information"
                                    name="description"
                                    defaultValue={fields.description || ""}
                                    {...$field("description", (e) =>
                                      onChange("description", e.target.value)
                                    )}
                                    className="form-control input-focus"
                                  ></textarea>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="form-group btn-last-group voc-btg">
                            <div className="row">
                              <div className="col-md-6">
                                <input
                                  type="hidden"
                                  name="voucherID"
                                  value={fields.voucherID || ""}
                                  {...$field("voucherID", (e) =>
                                    onChange("voucherID", e.target.value)
                                  )}
                                />
                                <button
                                  type="button"
                                  className="myaccount_update button"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    this.props.$submit(onValid, onInvalid);
                                  }}
                                >
                                  Submit
                                </button>
                                <button
                                  type="button"
                                  className="button"
                                  onClick={this.closevoucherfrm.bind(this, "")}
                                >
                                  Cancel
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* profile-info-div - end */}
                      </div>
                    </form>
                  )}
                  {this.state.showvoucherfrm == false && (
                    <div className="add-new-top">
                      <h4 className="form_grouptt">Your Voucher Information</h4>
                      <button
                        type="button"
                        className="button"
                        onClick={this.openvoucherfrm.bind(this, "")}
                      >
                        Add New
                      </button>
                    </div>
                  )}
                  <ul className="promo-new-ul">{this.displayVouchers()}</ul>
                  <div
                    id="imagedetails-popup"
                    className="white-popup mfp-hide popup_sec detail-popup"
                  >
                    {this.state.detailimage !== "" && (
                      <div className="cashback-image">
                        <img
                          src={
                            mediaUrl +
                            "/chopchop/vouchers/" +
                            this.state.detailimage
                          }
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
Form = validated(validationConfig)(Form);

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};
Form.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
};
export default connect(mapStateToProps, mapDispatchToProps)(Form);
