/* eslint-disable */
import { takeEvery, call, put } from "redux-saga/effects";
import { GET_PROMOTIONLIST, SET_PROMOTIONLIST } from "../actions";
import { apiUrl } from "../components/Helpers/Config";
import Axios from "axios";
import cookie from "react-cookies";

export const watchGetPromotionList = function* () {
  yield takeEvery(GET_PROMOTIONLIST, workerGetPromotionList);
};

function* workerGetPromotionList() {
  try {
    var conditions = "";
    if (
      cookie.load("UserId") !== "" &&
      typeof cookie.load("UserId") !== undefined &&
      typeof cookie.load("UserId") !== "undefined"
    ) {
      if (cookie.load("logintype") === "customer") {
        conditions = "?customer_id=" + cookie.load("UserId");
      }
    }

    const uri = apiUrl + "promobuddy/getAllPromotionList" + conditions;
    const result = yield call(Axios.get, uri);
    var resultArr = [];
    resultArr.push(result.data);
    yield put({ type: SET_PROMOTIONLIST, value: resultArr });
  } catch {
    console.log("Get Promotion list Failed");
  }
}
