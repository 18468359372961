/* eslint-disable */
import React from "react";
import { render } from "react-dom";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Provider } from "react-redux";
import { getStore } from "./store";

import "./common/css/font-awesome.css";
import "./common/css/style.css";
import "./common/css/responsive-style.css";
import "./common/css/owl.carousel.css";

import Home from "./components/Home/Home";
import Listpromo from "./components/Home/Listpromo";
import Myaccount from "./components/Myaccount/Myaccount";
import Account from "./components/Account/Account";
import Resetpassword from "./components/Account/Resetpassword";
import Logout from "./components/Myaccount/Logout";
import Page404 from "./Page404";

const store = getStore();

render(
  <Provider store={store}>
    <Router>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route
          exact
          path="/restaurants/promotions/:orderType"
          component={Listpromo}
        />
        <Route
          exact
          path="/restaurants/cashback/:orderType"
          component={Listpromo}
        />
        <Route
          exact
          path="/restaurants/vouchers/:orderType"
          component={Listpromo}
        />
        <Route exact path="/restaurants/promotions" component={Listpromo} />
        <Route exact path="/restaurants/cashback" component={Listpromo} />
        <Route exact path="/restaurants/vouchers" component={Listpromo} />
        <Route
          exact
          path="/restaurants/orderby/:orderType"
          component={Listpromo}
        />
        <Route exact path="/restaurants" component={Listpromo} />
        <Route exact path="/my-account" component={Myaccount} />
        <Route exact path="/business-info" component={Myaccount} />
        <Route exact path="/promotions" component={Myaccount} />
        <Route exact path="/rewards" component={Myaccount} />
        <Route exact path="/vouchers" component={Myaccount} />
        <Route exact path="/myaccount" component={Myaccount} />
        <Route path="/account/activation/:activationKey" component={Account} />
        <Route
          path="/account/useractivation/:activationKey"
          component={Account}
        />
        <Route
          path="/account/resetpassword/:resetKey"
          component={Resetpassword}
        />
        <Route
          path="/account/resetuserpassword/:resetKey"
          component={Resetpassword}
        />
        <Route path="/logout" component={Logout} />
        <Route component={Page404} />
      </Switch>
    </Router>
  </Provider>,
  document.getElementById("root")
);
