/* eslint-disable */
import { takeEvery, call, put } from "redux-saga/effects";
import { GET_USERPROMOTIONLIST, SET_USERPROMOTIONLIST } from "../actions";
import { apiUrl } from "../components/Helpers/Config";
import Axios from "axios";
import cookie from "react-cookies";

export const watchGetUserPromotionList = function* () {
  yield takeEvery(GET_USERPROMOTIONLIST, workerGetUserPromotionList);
};

function* workerGetUserPromotionList() {
  try {
    const uri =
      apiUrl +
      "promobuddy/getChopchopPromo?chopchop_user_id=" +
      cookie.load("UserId");
    const result = yield call(Axios.get, uri);
    var resultArr = [];
    resultArr.push(result.data);
    yield put({ type: SET_USERPROMOTIONLIST, value: resultArr });
  } catch {
    console.log("Get User Promotion list Failed");
  }
}
