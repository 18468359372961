import { SET_FORGETBUS_PASSWORD } from "../actions";

const forgetbuspassword = (state = [], action) => {
  switch (action.type) {
    case SET_FORGETBUS_PASSWORD:
      return [...action.value];
    default:
      return state;
  }
};

export default forgetbuspassword;
