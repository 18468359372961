/* eslint-disable */
import { takeEvery, call, put } from "redux-saga/effects";
import { GET_RESTAURANTLIST, SET_RESTAURANTLIST } from "../actions";
import { apiUrl } from "../components/Helpers/Config";
import Axios from "axios";
import cookie from "react-cookies";
export const watchGetRestaurantList = function* () {
  yield takeEvery(GET_RESTAURANTLIST, workerGetRestaurantList);
};

function* workerGetRestaurantList(orderingfilter) {
  try {
    console.log(orderingfilter, "orderingfilter");
    var conditions = "";
    if (
      cookie.load("UserId") !== "" &&
      typeof cookie.load("UserId") !== undefined &&
      typeof cookie.load("UserId") !== "undefined"
    ) {
      if (cookie.load("logintype") === "customer") {
        conditions = "?customer_id=" + cookie.load("UserId");
      }
    }
    if (
      orderingfilter.orderingfilter !== "" &&
      typeof orderingfilter.orderingfilter !== undefined &&
      typeof orderingfilter.orderingfilter !== "undefined"
    ) {
      if (conditions === "") {
        conditions += "?";
      }
      conditions += orderingfilter.orderingfilter;
    }
    const uri = apiUrl + "promobuddy/getRestaurants" + conditions;
    const result = yield call(Axios.get, uri);
    var resultArr = [];
    resultArr.push(result.data);
    yield put({ type: SET_RESTAURANTLIST, value: resultArr });
  } catch {
    console.log("Get Restaurant list Failed");
  }
}
