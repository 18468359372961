/* eslint-disable */
import { takeEvery, call, put } from "redux-saga/effects";
import { GET_USERVOUCHERLIST, SET_USERVOUCHERLIST } from "../actions";
import { apiUrl } from "../components/Helpers/Config";
import Axios from "axios";
import cookie from "react-cookies";

export const watchGetUserVoucherList = function* () {
  yield takeEvery(GET_USERVOUCHERLIST, workerGetUserVoucherList);
};

function* workerGetUserVoucherList() {
  try {
    const uri =
      apiUrl +
      "promobuddy/getChopchopVouchers?chopchop_user_id=" +
      cookie.load("UserId");
    const result = yield call(Axios.get, uri);
    var resultArr = [];
    resultArr.push(result.data);
    yield put({ type: SET_USERVOUCHERLIST, value: resultArr });
  } catch {
    console.log("Get User Voucher list Failed");
  }
}
