/* eslint-disable */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import update from "immutability-helper";
import cookie from "react-cookies";
import { showAlert, showLoader, hideLoader } from "../Helpers/SettingHelper";
import { baseUrl } from "../Helpers/Config";

import {
  Login,
  Forgotpassword,
  Signup,
  Businesssignup,
  Businesslogin,
  Forgotuserpassword,
} from "../../components/Myaccount/Index";
import logo from "../../common/images/logo.svg";
import heart from "../../common/images/heart.svg";
import plogo from "../../common/images/popup-logo.png";
import {
  GET_BUSINESSREGISTRATION,
  GET_BUSINESSLOGINDATA,
  GET_LOGINDATA,
  GET_REGISTRATION,
  GET_FORGET_PASSWORD,
  GET_FORGETBUS_PASSWORD,
} from "../../actions";
var qs = require("qs");
class Header extends Component {
  constructor(props) {
    super(props);
    var UserId = "";
    if (
      cookie.load("UserId") !== "" &&
      typeof cookie.load("UserId") !== undefined &&
      typeof cookie.load("UserId") !== "undefined"
    ) {
      UserId = cookie.load("UserId");
    }
    this.state = {
      UserId: UserId,
      regbusinessstatus: "initiating",
      regstatus: "initiating",
      fields: {
        email: "",
        password: "",
      },
      fieldssignup: {
        firstname: "",
        lastname: "",
        email: "",
        password: "",
        rePassword: "",
        mobile: "",
        terms: "",
      },
      fieldsbusiness: {
        email: "",
        password: "",
      },
      fieldsbusinesssignup: {
        restaurantname: "",
        postal_code: "",
        address: "",
        firstname: "",
        lastname: "",
        email: "",
        password: "",
        rePassword: "",
        mobile: "",
        pdpa_consent: "",
        terms: "",
      },
      fpstatus: "",
      fieldsfgtpassword: {
        email: "",
      },
      fpbusstatus: "",
      fieldsfgtbuspassword: {
        email: "",
      },
    };
  }
  componentDidMount() {
    $(document).ready(function () {
      $("html, body").animate({ scrollTop: 0 }, 500);
      $("body").on("click", ".popup-modal-dismiss", function () {
        $.magnificPopup.close();
      });
      /* if ($(".open-popup-link").length > 0) {
        $(".open-popup-link").magnificPopup({
          type: "inline",
          midClick: true, // Allow opening popup on middle mouse click. Always set it to true if you don't provide alternative source in href.
        });
      } */
    });
  }
  componentWillReceiveProps(PropsDt) {
    if (PropsDt.businesslogindata !== this.props.businesslogindata) {
      this.doBusinessLogin(PropsDt.businesslogindata[0]);
    }
    if (this.state.regbusinessstatus === "loading") {
      if (PropsDt.businessregistration !== undefined) {
        this.setState({ regbusinessstatus: "ok" });
        this.showMessage(PropsDt.businessregistration[0]);
      }
    }

    if (PropsDt.logindata !== this.props.logindata) {
      this.doLogin(PropsDt.logindata[0]);
    }

    if (this.state.regstatus === "loading") {
      if (PropsDt.registration !== undefined) {
        this.setState({ regstatus: "ok" });
        this.showMessage(PropsDt.registration[0]);
      }
    }
    if (this.state.fpstatus === "loading") {
      if (PropsDt.forgetpassword !== undefined) {
        this.setState({ fpstatus: "ok" });
        this.showMessage(PropsDt.forgetpassword[0]);
      }
    }
    if (this.state.fpbusstatus === "loading") {
      if (PropsDt.forgetbuspassword !== undefined) {
        this.setState({ fpbusstatus: "ok" });
        console.log(PropsDt.forgetbuspassword, "PropsDt.forgetbuspassword");
        this.showMessage(PropsDt.forgetbuspassword[0]);
      }
    }
  }
  showMessage(response) {
    hideLoader("signup_submit", "class");
    hideLoader("forgotpassword-cls", "class");
    console.log(response, "responseresponse");
    if (response.status === "ok") {
      showAlert("Success", response.message);
    } else {
      if (response.form_error) {
        showAlert("Error", response.form_error);
      } else {
        showAlert("Error", response.message);
      }
    }
    $.magnificPopup.open({
      items: {
        src: ".alert_popup",
      },
      type: "inline",
    });
  }
  openPopup(popupID, e) {
    e.preventDefault();
    $.magnificPopup.close();
    $.magnificPopup.open({
      items: {
        src: popupID,
      },
      type: "inline",
    });
  }

  fieldChangeBusiness = (field, value) => {
    this.setState(
      update(this.state, { fieldsbusiness: { [field]: { $set: value } } })
    );
  };
  handleBusinessSignin = () => {
    const formPayload = this.state.fieldsbusiness;
    var qs = require("qs");
    var postObject = {
      login_username: formPayload.email,
      login_password: formPayload.password,
    };
    showLoader("login_submit", "class");
    this.props.getBusinessLoginData(qs.stringify(postObject));
  };
  doBusinessLogin(fbloginData) {
    hideLoader("login_submit", "class");
    if (fbloginData.status === "ok") {
      $.magnificPopup.close();
      cookie.save("UserId", fbloginData.result_set.chopchop_user_id, {
        path: "/",
      });
      cookie.save("logintype", "business", {
        path: "/",
      });
      this.props.history.push("/my-account");
    } else {
      showAlert("Error", fbloginData.message);
      $.magnificPopup.open({
        items: {
          src: ".alert_popup",
        },
        type: "inline",
      });
    }
  }

  fieldChangeBusinessSignup = (field, value) => {
    if (field == "terms") {
      value = $("#terms").prop("checked");
    }
    this.setState(
      update(this.state, { fieldsbusinesssignup: { [field]: { $set: value } } })
    );
  };
  handleBusinessSignup = () => {
    const formPayload = this.state.fieldsbusinesssignup;
    this.setState({ regbusinessstatus: "loading" });

    var qs = require("qs");
    var postObject = {
      chopchop_first_name: formPayload.firstname,
      chopchop_last_name: formPayload.lastname,
      chopchop_company_name: formPayload.restaurantname,
      chopchop_postal_code: formPayload.postal_code,
      chopchop_address: formPayload.address,
      chopchop_mobile: formPayload.mobile,
      chopchop_email: formPayload.email,
      chopchop_password: formPayload.password,
      site_url: baseUrl,
    };

    showLoader("signup_submit", "class");
    this.props.getBusinessRegistration(qs.stringify(postObject));
  };

  /* signin - start*/
  fieldChange = (field, value) => {
    this.setState(update(this.state, { fields: { [field]: { $set: value } } }));
  };

  handleSignin = () => {
    const formPayload = this.state.fields;
    var qs = require("qs");
    var postObject = {
      login_username: formPayload.email,
      login_password: formPayload.password,
    };
    showLoader("login_submit", "class");
    this.props.getLoginData(qs.stringify(postObject));
  };

  doLogin(fbloginData) {
    hideLoader("login_submit", "class");
    if (fbloginData.status === "ok") {
      $.magnificPopup.close();
      var mobileno = "";
      if (
        typeof fbloginData.result_set.chopchop_mobile === "undefined" ||
        fbloginData.result_set.chopchop_mobile === "null" ||
        fbloginData.result_set.chopchop_mobile === ""
      ) {
        mobileno = "";
      } else {
        mobileno = fbloginData.result_set.chopchop_mobile;
      }
      cookie.save("UserId", fbloginData.result_set.chopchop_customer_id, {
        path: "/",
      });
      cookie.save("UserEmail", fbloginData.result_set.chopchop_email, {
        path: "/",
      });
      cookie.save(
        "UserFname",
        fbloginData.result_set.chopchop_first_name !== ""
          ? fbloginData.result_set.chopchop_first_name
          : "",
        { path: "/" }
      );
      cookie.save(
        "UserLname",
        fbloginData.result_set.chopchop_last_name !== ""
          ? fbloginData.result_set.chopchop_last_name
          : "",
        { path: "/" }
      );
      cookie.save("UserMobile", mobileno, { path: "/" });
      cookie.save("logintype", "customer", {
        path: "/",
      });

      const { history } = this.props;
      history.push("/myaccount");
    } else {
      cookie.remove("loginpopupTrigger", { path: "/" });
      showAlert("Error", fbloginData.message);
      $.magnificPopup.open({
        items: {
          src: ".alert_popup",
        },
        type: "inline",
      });
    }
  }
  /* signin - end*/

  /* for signup - start*/
  fieldChangeSignup = (field, value) => {
    if (field == "terms") {
      value = $("#terms").prop("checked");
    }
    if (field == "pdpa_consent") {
      value = $("#pdpa_consent").prop("checked");
    }
    this.setState(
      update(this.state, { fieldssignup: { [field]: { $set: value } } })
    );
  };

  handleSignup = () => {
    const formPayload = this.state.fieldssignup;
    this.setState({ regstatus: "loading" });

    var postObject = {
      customer_first_name: formPayload.firstname,
      customer_last_name: formPayload.lastname,
      customer_email: formPayload.email,
      customer_password: formPayload.password,
      customer_phone: formPayload.mobile,
      site_url: baseUrl,
    };

    showLoader("signup_submit", "class");
    this.props.getRegistration(qs.stringify(postObject));
  };

  /* for signup - end*/

  /*forgt customer password  - start*/
  fieldforgot = (field, value) => {
    this.setState(
      update(this.state, { fieldsfgtpassword: { [field]: { $set: value } } })
    );
  };

  forgotpassword = () => {
    this.setState({ fpstatus: "loading" });
    const formPayload = this.state.fieldsfgtpassword;
    var postObject = {
      email_address: formPayload.email,
      site_url: baseUrl,
    };
    showLoader("forgotpassword-cls", "class");
    this.props.getForgetPassword(qs.stringify(postObject));
  };
  /*forget customer password  - end*/

  /*forgt user password  - start*/
  fieldbusforgot = (field, value) => {
    this.setState(
      update(this.state, { fieldsfgtbuspassword: { [field]: { $set: value } } })
    );
  };

  forgotbuspassword = () => {
    this.setState({ fpbusstatus: "loading" });
    const formPayload = this.state.fieldsfgtbuspassword;
    console.log(formPayload, "formPayload");
    var postObject = {
      email_address: formPayload.email,
      site_url: baseUrl,
    };
    showLoader("forgotpassword-cls", "class");
    this.props.getForgetBusPassword(qs.stringify(postObject));
  };
  /*forget user password  - end*/

  openRegister(event) {
    event.preventDefault();
    if (this.state.UserId !== "") {
      if (
        cookie.load("logintype") !== "" &&
        typeof cookie.load("logintype") !== undefined &&
        typeof cookie.load("logintype") !== "undefined"
      ) {
        if (cookie.load("logintype") !== "customer") {
          this.props.history.push("/business-info");
        } else {
          $.magnificPopup.open({
            items: {
              src: "#warning-login-popup",
            },
            type: "inline",
          });
        }
      }
    } else {
      $.magnificPopup.open({
        items: {
          src: "#bus-login-popup",
        },
        type: "inline",
      });
    }
  }
  proceedLogout() {
    cookie.save("openbusinesslogin", "Yes", {
      path: "/",
    });
    $.magnificPopup.close();
    this.props.history.push("/logout");
  }

  render() {
    return (
      <header className={this.props.match.path !== "/" ? "innerpage" : ""}>
        <div className="header-bottom">
          <div className="container flex">
            <div className="nav-top" style={{ display: "none" }}>
              <div className="rel humbur fr">
                <a href={void 0} className="toggle-button toggle">
                  <span className="top-trigger c-trigger"> </span>
                  <span className="middle-trigger c-trigger"> </span>
                  <span className="bottom-trigger c-trigger"> </span>
                </a>
              </div>
            </div>
            <div className="header-lhs">
              <Link to={"/"}>
                {" "}
                <img src={logo} alt="logo" />{" "}
              </Link>
            </div>
            <div className="header-rhs">
              <div className="navigation">
                <ul className="menu">
                  <li>
                    {" "}
                    <Link to={"/"}>
                      <span>Home</span>
                    </Link>{" "}
                  </li>
                  <li>
                    <Link to={"/"}>
                      <span>About Us</span>{" "}
                    </Link>
                    {/* <ul>
                          <li><a href="#">Sub menu</a></li>
                          <li><a href="#">Sub menu</a></li>	
                          <li><a href="#">Sub menu</a></li>
                        </ul> */}
                  </li>
                  <li>
                    {" "}
                    <a href="#">
                      <span>Contact Us</span>
                    </a>{" "}
                  </li>
                  <li>
                    {" "}
                    <a href="https://support.ninjaos.com/" target="_blank">
                      <span>Support</span>
                    </a>{" "}
                  </li>
                </ul>
              </div>
              <div className="header-links">
                <ul>
                  {cookie.load("UserId") ? (
                    <>
                      <li className="login-menu">
                        <Link
                          to={
                            cookie.load("logintype") === "customer"
                              ? "myaccount"
                              : "my-account"
                          }
                        >
                          My Account
                        </Link>
                        <span>|</span>
                        <Link to="/logout">Logout</Link>
                      </li>
                    </>
                  ) : (
                    <>
                      <li className="login-menu">
                        <a
                          href="#login"
                          onClick={this.openPopup.bind(
                            this,
                            "#cust-login-popup"
                          )}
                        >
                          Login
                        </a>
                        <span>|</span>
                        <a
                          href="#cust-signup-popup"
                          onClick={this.openPopup.bind(
                            this,
                            "#cust-signup-popup"
                          )}
                        >
                          Register
                        </a>
                      </li>
                    </>
                  )}

                  <li className="favourite">
                    <a href="#">
                      {" "}
                      <img src={heart} alt="" />{" "}
                    </a>
                  </li>
                  <li className="list-button">
                    <a
                      href={void 0}
                      onClick={this.openRegister.bind(this)}
                      className="button button-fill"
                    >
                      List your Business
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="mobile-list-button">
            <div className="mobile-login-section">
              {cookie.load("UserId") ? (
                <>
                  <li className="login-menu">
                    <Link
                      to={
                        cookie.load("logintype") === "customer"
                          ? "myaccount"
                          : "my-account"
                      }
                    >
                      My Account
                    </Link>
                    <span>|</span>
                    <Link to="/logout">Logout</Link>
                  </li>
                </>
              ) : (
                <>
                  <li className="login-menu">
                    <a
                      href="#login"
                      onClick={this.openPopup.bind(this, "#cust-login-popup")}
                    >
                      Login
                    </a>
                    <span>|</span>
                    <a
                      href="#cust-signup-popup"
                      onClick={this.openPopup.bind(this, "#cust-signup-popup")}
                    >
                      Register
                    </a>
                  </li>
                </>
              )}
            </div>
            <div className="mobile-list-business-section">
              <a
                href={void 0}
                onClick={this.openRegister.bind(this)}
                className="button button-fill"
              >
                List your Business
              </a>
            </div>
          </div>
        </div>
        {/* login popup */}

        <div
          id="cust-login-popup"
          className="mfp-hide popup_sec cust-login-popup"
        >
          <div className="pop-whole full-login-new">
            <div className="full-login-rhs full-login">
              <div className="full-login-rhs-header">
                <h3>Have An Account?</h3>
                <p>Enter Your User Login Here</p>
              </div>
              <Login
                fields={this.state.fields}
                onChange={this.fieldChange}
                onValid={this.handleSignin}
                onInvalid={() => console.log("Form invalid!")}
              />
              OR
              <a href="#" className="button">
                Sign Up
              </a>
            </div>
          </div>
        </div>
        <div
          id="cust-signup-popup"
          className="mfp-hide popup_sec cust-signup-popup"
        >
          <div className="pop-whole full-login-new">
            <div className="full-login-rhs full-login">
              <div className="full-login-rhs-header">
                <h3>Create an Account </h3>
                <p>Update your information and continue</p>
              </div>
              <Signup
                fields={this.state.fieldssignup}
                onChange={this.fieldChangeSignup}
                onValid={this.handleSignup}
                onInvalid={() => console.log("Form invalid!")}
              />
            </div>
          </div>
        </div>
        <div
          id="forgot-password-popup"
          className="mfp-hide popup_sec cust-signup-popup"
        >
          <div className="pop-whole full-login-new">
            <div className="full-login-rhs full-login">
              <div className="full-login-rhs-header">
                <h3>Forgot your password? </h3>
                <p>You can reset your password here.</p>
              </div>
              <Forgotpassword
                fields={this.state.fieldsfgtpassword}
                onChange={this.fieldforgot}
                onValid={this.forgotpassword}
                onInvalid={() => console.log("Form invalid!")}
              />
            </div>
          </div>
        </div>
        <div
          id="forgotuser-password-popup"
          className="mfp-hide popup_sec cust-signup-popup"
        >
          <div className="pop-whole full-login-new">
            <div className="full-login-rhs full-login">
              <div className="full-login-rhs-header">
                <h3>Forgot your password? </h3>
                <p>You can reset your password here.</p>
              </div>
              <Forgotuserpassword
                fields={this.state.fieldsfgtbuspassword}
                onChange={this.fieldbusforgot}
                onValid={this.forgotbuspassword}
                onInvalid={() => console.log("Form invalid!")}
              />
            </div>
          </div>
        </div>
        <div
          id="bus-login-popup"
          className="mfp-hide popup_sec bus-login-popup"
        >
          <div className="pop-whole full-login-new">
            <div className="full-login-lhs">
              <img src={plogo} alt="Logo" />
            </div>
            <div className="full-login-rhs">
              <div className="full-login-rhs-header">
                <h3>I Have Business Listing Account</h3>
                <p>Enter Your Business Account Details here</p>
              </div>
              <Businesslogin
                fields={this.state.fieldsbusiness}
                onChange={this.fieldChangeBusiness}
                onValid={this.handleBusinessSignin}
                onInvalid={() => console.log("Form invalid!")}
              />
              OR
              <a
                href="#"
                onClick={this.openPopup.bind(this, "#bus-signup-popup")}
                className="button"
              >
                Sign Up
              </a>
            </div>
          </div>
        </div>
        <div
          id="bus-signup-popup"
          className="mfp-hide popup_sec bus-signup-popup"
        >
          <div className="pop-whole full-login-new">
            <div className="full-login-lhs">
              <img src={plogo} alt="Logo" />
            </div>
            <div className="full-login-rhs">
              <div className="full-login-rhs-header">
                <h3>List Your Business?</h3>
                <p>Update your information and continue for Account Register</p>
              </div>
              <Businesssignup
                fields={this.state.fieldsbusinesssignup}
                onChange={this.fieldChangeBusinessSignup}
                onValid={this.handleBusinessSignup}
                onInvalid={() => console.log("Form invalid!")}
              />
            </div>
          </div>
        </div>
        <div
          id="warning-login-popup"
          className="mfp-hide popup_sec warning-login-popup"
        >
          <div className="pop-whole full-login-new">
            <div className="full-login-rhs">
              <div className="full-login-rhs-header">
                <p>
                  Would you like to register a business account to list your
                  business
                </p>
              </div>
              <div className="extra-btn">
                <button type="button" className="button popup-modal-dismiss">
                  No
                </button>
                <button
                  type="button"
                  className="button"
                  onClick={this.proceedLogout.bind(this)}
                >
                  Yes
                </button>
              </div>
            </div>
          </div>
        </div>
      </header>
    );
  }
}

const mapStateTopProps = (state) => {
  console.log(state, "statestate");
  return {
    businessregistration: state.businessregistration,
    businesslogindata: state.businesslogin,
    logindata: state.login,
    registration: state.registration,
    forgetpassword: state.forgetpassword,
    forgetbuspassword: state.forgetbuspassword,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getBusinessRegistration: (formPayload) => {
      dispatch({ type: GET_BUSINESSREGISTRATION, formPayload });
    },
    getBusinessLoginData: (formPayload) => {
      dispatch({ type: GET_BUSINESSLOGINDATA, formPayload });
    },
    getLoginData: (formPayload) => {
      dispatch({ type: GET_LOGINDATA, formPayload });
    },
    getRegistration: (formPayload) => {
      dispatch({ type: GET_REGISTRATION, formPayload });
    },
    getForgetPassword: (formPayload) => {
      dispatch({ type: GET_FORGET_PASSWORD, formPayload });
    },
    getForgetBusPassword: (formPayload) => {
      dispatch({ type: GET_FORGETBUS_PASSWORD, formPayload });
    },
  };
};

export default connect(mapStateTopProps, mapDispatchToProps)(Header);
