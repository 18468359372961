/* eslint-disable */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Accordion, AccordionItem } from "react-light-accordion";
import "react-light-accordion/demo/css/index.css";
import { projectTitle } from "../Helpers/Config";
import flogo from "../../common/images/logo.svg";
import close from "../../common/images/close.svg";
class Footer extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    var todayTimeSp = new Date();
    var yearSp = todayTimeSp.getFullYear();

    return (
      <footer className="footer-main">
        <div className="container">
          <div className="footer-top">
            <div className="footer-newletter">
              <span>Sign up for our newsletter</span>
              <h4>
                Be the First to Get the Scoop on Our Freshest Deals, Launches &
                Recipes!
              </h4>
              <div className="f-newletter-form">
                <input type="text" placeholder="Email Address" />
                <button type="submit" className="button">
                  Subscribe
                </button>
              </div>
              <div className="f-newletter-bottom">
                <input type="checkbox" className="subscribe_accept" />
                <label>
                  {" "}
                  By signing up, i agree to receive communications about{" "}
                  {projectTitle}’s services store events and matters via email
                  and i have also acknowledge & agreed to {projectTitle}’s
                  Privacy Policy and Terms & Conditions
                </label>
              </div>
            </div>

            <div className="footer-logo">
              <Link to="/" title="ChopChop" className="float-logo">
                {" "}
                <img src={flogo} alt="ChopChop" />{" "}
              </Link>
              <div className="social-media-parent">
                <h6>Chop Chop Rewards</h6>
                <div className="social-media">
                  <a href="https://www.facebook.com/" target="_blank">
                    {" "}
                    <img alt="Facebook" src="/img/facebook.svg" />{" "}
                  </a>{" "}
                  <a href="https://www.instagram.com/" target="_blank">
                    {" "}
                    <img alt="Instagram" src="/img/instagram.svg" />{" "}
                  </a>
                </div>
              </div>
            </div>
            <div className="footer-nav">
              <div className="footer-nav-inner">
                <h5>About Us</h5>
                <ul>
                  <li>
                    <a href="/our-story">Our Story</a>
                  </li>
                  <li>
                    <a href="/locations">Visit Us</a>
                  </li>
                </ul>
              </div>
              <div className="footer-nav-inner">
                <h5>Helpful Links</h5>
                <ul>
                  <li>
                    <a href="/faq">FAQ</a>
                  </li>
                  <li>
                    <a href="/terms-and-conditions">Terms &amp; Conditions</a>
                  </li>
                  <li>
                    <a href="/privacy-policy">Privacy Policy</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="mobile-footer-nav">
              <Accordion>
                <AccordionItem title="About Us">
                  <ul>
                    <li>
                      <a href="/page/about-us">About Us</a>
                    </li>
                    <li>
                      <a href="/locations">Visit Us</a>
                    </li>
                  </ul>
                </AccordionItem>
                <AccordionItem title="Helpful Links">
                  <ul>
                    <li>
                      <Link to={"/faq"}>FAQ</Link>
                    </li>
                    <li>
                      <Link to={"/privacy-policy"}>Privacy Policy</Link>
                    </li>
                  </ul>
                </AccordionItem>
              </Accordion>
            </div>
          </div>
        </div>
        <div className="copyright-section">
          <p>
            Copyright {yearSp} {projectTitle}.
          </p>
        </div>

        <div className="scrolltop" id="scrollbutton">
          <a href="/" className="disbl_href_action">
            <span>
              <i className="fa fa-angle-double-up ars"></i>
              <i className="fa fa-angle-up ars1"></i>
              <i className="fa fa-angle-up ars2"></i>
            </span>
          </a>
        </div>
        <div className="overlay" id="overlay">
          <div className="pattern">
            <div className="over-close">
              {" "}
              <img src={close} className="close-icon" />{" "}
            </div>
            <div className="nav-logo textcenter">
              <a href="#">
                {" "}
                <img src={flogo} alt="Logo" />{" "}
              </a>
            </div>
            <nav id="mob_menu">
              <ul className="device_nav">
                <li>
                  {" "}
                  <a href="#">
                    <span>Home</span>
                  </a>{" "}
                </li>
                <li>
                  {" "}
                  <a href="#">
                    <span>About Us</span>{" "}
                  </a>
                </li>
                <li>
                  {" "}
                  <a href="#">
                    <span>Contact Us</span>
                  </a>
                  {/* <ul>
                    <li>
                      <a href="#">Sub menu</a>
                    </li>
                    <li>
                      <a href="#">Sub menu</a>
                    </li>
                    <li>
                      <a href="#">Sub menu</a>
                    </li>
                  </ul> */}
                </li>
                <li>
                  {" "}
                  <a href="#">
                    <span>Support</span>
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </footer>
    );
  }
}

const mapStateTopProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateTopProps, mapDispatchToProps)(Footer);
