/* eslint-disable */

import React from "react";
import { validated } from "react-custom-validation";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Select from "react-select";
import DatePicker from "react-datepicker";
import { setMinutes, setHours, format } from "date-fns";
import "react-datepicker/dist/react-datepicker.css";
import Sidebar from "./Sidebar";
import { mediaUrl } from "../Helpers/Config";
import { GET_TAGLIST } from "../../actions";

const isEmpty = (value) => (value === "" ? "This field is required." : null);
const phonenumberPattern = /^[0-9]$/;
const isMobile = (mobile) =>
  mobile.match(phonenumberPattern) ? null : "This is not a valid postal code.";
function validationConfig(props) {
  const {
    company_name,
    address,
    postal_code,
    order_url,
    availability,
    delivery_start,
    min_order_amount,
    description,
  } = props.fields;
  return {
    fields: [
      "company_name",
      "address",
      "postal_code",
      "order_url",
      "availability",
      "delivery_start",
      "min_order_amount",
      "description",
    ],

    validations: {
      company_name: [[isEmpty, company_name]],
      /*  postal_code: [
        [isMobile, postal_code],
      ], */
    },
  };
}

class Form extends React.Component {
  constructor(props) {
    var Maxdate = new Date();
    Maxdate.setFullYear(Maxdate.getFullYear() - 10);
    super(props);
    this.state = {
      status: "Loading",
      delivery_start: "",
      taglist: [],
      detailimage: "",
    };
    this.props.getTagList();
    this.handleChangeDate = this.handleChangeDate.bind(this);
  }

  componentWillReceiveProps(PropsData) {
    if (this.state.taglist !== PropsData.taglist) {
      if (PropsData.taglist.length > 0) {
        var taglist = [];
        PropsData.taglist.map((item) => {
          item.tag.map((item1) => {
            taglist.push({
              value: item1.ol_tag_primary_id,
              label: item1.ol_tag_name,
            });
          });
        });
        this.setState({ taglist: taglist });
      }
    }
  }

  handleChangeDate(datevalue) {
    var dateval = new Date(datevalue);
    dateval = format(dateval, "h:mm aa");
    this.setState({ delivery_start: datevalue });
    this.handleChange("delivery_start", datevalue);
  }

  handleChange = (item, value) => {
    this.props.onChange(item, value);
    this.setState({ ...this.state, [item]: value });
  };
  opendDetailImg(detailimage) {
    this.setState({ detailimage: detailimage }, function () {
      $.magnificPopup.open({
        items: {
          src: "#imagedetails-popup",
        },
        type: "inline",
      });
    });
  }

  render() {
    const { fields, onChange, onValid, onInvalid, $field, $validation } =
      this.props;

    let errMsgCompanyName;

    var delivery_start = new Date();
    if (this.state.delivery_start !== "") {
      delivery_start = this.state.delivery_start;
    } else if (
      fields.delivery_start !== "" &&
      fields.delivery_start !== null &&
      fields.delivery_start !== "0000-00-00 00:00:00"
    ) {
      delivery_start = new Date(fields.delivery_start);
    }

    if ($validation.company_name.error.reason !== undefined) {
      errMsgCompanyName = $validation.company_name.show && (
        <span className="error">{$validation.company_name.error.reason}</span>
      );
    }
    return (
      <div className="container-one">
        <div className="tab-content">
          <div className="tab-pane account-tab-section fade in active">
            <div className="tab_mobrow main_tabin">
              <div className="account_sec">
                <Sidebar pageName="business-info" {...this.props} />
                <div className="accsec_right">
                  <div className="acc-inform">
                    {/* profile-info-div - end */}
                    <div className="profile-info-div">
                      <h4 className="form_grouptt">
                        Your Business Information
                      </h4>
                      <div className="form-group full-fg">
                        <div className="row">
                          <div className="col-md-12">
                            <div className="focus-out focused">
                              <input
                                type="text"
                                name="company_name"
                                placeholder="Business Name"
                                className="form-control input-focus"
                                value={fields.company_name || ""}
                                {...$field("company_name", (e) =>
                                  onChange("company_name", e.target.value)
                                )}
                              />
                              {errMsgCompanyName}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="form-group  half-fg">
                        <div className="row">
                          <div className="col-md-6">
                            <div className="focus-out">
                              <input
                                type="number"
                                placeholder="Postal Code"
                                name="postal_code"
                                value={fields.postal_code || ""}
                                {...$field("postal_code", (e) =>
                                  onChange("postal_code", e.target.value)
                                )}
                                className="form-control input-focus"
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="focus-out">
                              <input
                                type="text"
                                placeholder="Address Line"
                                name="address"
                                value={fields.address || ""}
                                {...$field("address", (e) =>
                                  onChange("address", e.target.value)
                                )}
                                className="form-control input-focus"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="form-group half-fg">
                        <div className="row">
                          <div className="col-md-6">
                            <div className="focus-out">
                              <Select
                                closeMenuOnSelect={false}
                                value={fields.availability}
                                selectMultiple={true}
                                placeholder="Select Availability"
                                isMulti
                                onChange={onChange.bind(this, "availability")}
                                options={[
                                  { value: "Delivery", label: "Delivery" },
                                  { value: "Pickup", label: "Pickup" },
                                ]}
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="focus-out">
                              <DatePicker
                                placeholderText="Delviery Start At"
                                showTimeSelect
                                showTimeSelectOnly
                                timeIntervals={5}
                                timeCaption="Time"
                                dateFormat="h:mm aa"
                                onChange={this.handleChangeDate}
                                className="form-control"
                                selected={delivery_start}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="form-group half-fg">
                        <div className="row">
                          <div className="col-md-6">
                            <div className="focus-out focused">
                              <input
                                type="text"
                                placeholder="Min Order Amount"
                                name="min_order_amount"
                                value={fields.min_order_amount || ""}
                                {...$field("min_order_amount", (e) =>
                                  onChange("min_order_amount", e.target.value)
                                )}
                                className="form-control input-focus"
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="focus-out">
                              <input
                                type="text"
                                placeholder="Ordering Site URL"
                                name="order_url"
                                value={fields.order_url || ""}
                                {...$field("order_url", (e) =>
                                  onChange("order_url", e.target.value)
                                )}
                                className="form-control input-focus"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="form-group half-fg">
                        <div className="row">
                          <div className="col-md-6">
                            <div className="focus-out">
                              <input
                                type="file"
                                name="restaurant_image"
                                accept="image/*"
                                id="restaurant_image"
                                placeholder="Restaurant Image"
                                className="form-control input-focus"
                              />
                            </div>
                            {fields.restarentimage !== "" &&
                              fields.restarentimage !== null && (
                                <a
                                  href={void 0}
                                  onClick={this.opendDetailImg.bind(
                                    this,
                                    fields.restarentimage
                                  )}
                                  className="view-image"
                                >
                                  View Image
                                </a>
                              )}
                          </div>
                        </div>
                      </div>
                      <div className="form-group full-fg">
                        <div className="row">
                          <div className="col-md-12">
                            <div className="focus-out">
                              <Select
                                closeMenuOnSelect={false}
                                value={fields.tag}
                                selectMultiple={true}
                                placeholder="Select Tag"
                                isMulti
                                onChange={onChange.bind(this, "tag")}
                                options={this.state.taglist}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="form-group full-fg">
                        <div className="row">
                          <div className="col-md-12">
                            <div className="focus-out">
                              <textarea
                                type="text"
                                placeholder="About Your Business"
                                name="description"
                                defaultValue={fields.description || ""}
                                {...$field("description", (e) =>
                                  onChange("description", e.target.value)
                                )}
                                className="form-control input-focus"
                              ></textarea>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="form-group btn-last-group">
                        <div className="row">
                          <div className="col-md-6">
                            <button
                              type="button"
                              className="myaccount_update button"
                              onClick={(e) => {
                                e.preventDefault();
                                this.props.$submit(onValid, onInvalid);
                              }}
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      id="imagedetails-popup"
                      className="white-popup mfp-hide popup_sec detail-popup"
                    >
                      {this.state.detailimage !== "" && (
                        <div className="cashback-image">
                          <img
                            src={
                              mediaUrl +
                              "/chopchop/restaurants/" +
                              this.state.detailimage
                            }
                          />
                        </div>
                      )}
                    </div>

                    {/* profile-info-div - end */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
Form = validated(validationConfig)(Form);

const mapStateToProps = (state) => {
  var taglist = Array();
  if (Object.keys(state.taglist).length > 0) {
    if (state.taglist[0].status === "ok") {
      taglist = state.taglist[0].result_set;
    }
  }
  return { taglist: taglist };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getTagList: () => {
      dispatch({ type: GET_TAGLIST });
    },
  };
};
Form.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
};
export default connect(mapStateToProps, mapDispatchToProps)(Form);
