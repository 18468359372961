/* Live */
export const appId = "CD32B901-B360-46B7-93CC-117B499F3092";
export const projectTitle = "Chop Chop Rewards";
export const apiUrl = "https://ccpl.ninjaos.com/api/";
export const apiUrlV2 = "https://ccpl.ninjaos.com/apiv2/";
export const apiUrlPro = "https://ccpl.ninjaos.com/ninjapro/";
export const apiUrlNotify = "https://ccpl.ninjaos.com/Pushorder/";
//export const baseUrl = "deals.chopchoprewards.com/";
export const baseUrl = "https://chopchoprewards.promobuddy.asia/";
//export const baseUrl = "http://localhost:3000/";

export const timThumpUrl = "https://ccpl.ninjaos.com/timthumb.php?src=";
export const mediaUrl = "https://ccpl.ninjaos.com/media/dev_team/";
export const blogImageUrl = "https://ccpl.ninjaos.com/media/dev_team/blog/";
export const tagImageUrl = "https://ccpl.ninjaos.com/media/dev_team/tag/";
export const proFolderUrl = "https://ccpl.ninjaos.com/media/dev_team/products/";

export const stripeImage = "/img/stripe_logo.png";

export const deliveryId = "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11";
export const pickupId = "718B1A92-5EBB-4F25-B24D-3067606F67F0";
export const cateringId = "EB62AF63-0410-47CC-9464-038E796E28C4";

export const CountryTxt = "Singapore";
export const productNoImg = "/img/product-noimg.jpg";
export const stripeCompany = "Chop Chop Rewards";

export const fbAppId = "971119207095643";

export const stripeKey = "pk_test_mSKQhHZYbRvm1YvuX9mKN4zx";
export const stripeEmail = "dev@jankosoft.com";
export const stripeReference = "chopchop";
export const stripeDesc = "My Checkout";
export const stripeCurrency = "SGD";
export const currencySymbol = "$";
