/* eslint-disable */
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { apiUrl } from "../Helpers/Config";
import axios from "axios";

/* import modules */
import Header from "../Layout/Header";
import { showAlert } from "../Helpers/SettingHelper";
var qs = require("qs");

class Resetpassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {},
      errors: {},
    };
  }

  componentDidMount() {
    var resetKey = this.props.match.params.resetKey;
    this.setState({ resetKey: resetKey });

    /* for account reset - start */
    if (typeof resetKey === "undefined" || resetKey === "") {
      showAlert("Error", "Please check your reset key.");

      const { history } = this.props;

      history.push("/");
    } else {
      $.magnificPopup.open({
        items: {
          src: "#reset-password-popup",
        },
        type: "inline",
        closeOnBgClick: false,
      });
    }
  }

  handleValidation() {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;

    //new password
    if (!fields["new_password"]) {
      formIsValid = false;
      errors["new_password"] = "New Password is required.";
    }
    //confirm password
    if (!fields["confirm_password"]) {
      formIsValid = false;
      errors["confirm_password"] = "Confirm Password is required.";
    }
    this.setState({ errors: errors });
    return formIsValid;
  }

  resetpasswordSubmit(e) {
    e.preventDefault();
    $(".spn-error").html("");
    if (this.handleValidation()) {
      var postObject = {
        key: this.state.resetKey,
        password: this.state.fields["new_password"],
        confirmpassword: this.state.fields["confirm_password"],
      };
      $(".spn-error").html("");
      $("#form-msg").html("");
      var url = "";
      if (this.props.match.path === "/account/resetuserpassword/:resetKey") {
        url = "resetuserpassword";
      } else {
        url = "resetcustomerpassword";
      }
      axios
        .post(apiUrl + "promobuddy/" + url, qs.stringify(postObject))
        .then((res) => {
          if (res.data.status === "ok") {
            showAlert("Success", res.data.message);
            $.magnificPopup.open({
              items: {
                src: ".alert_popup",
              },
              type: "inline",
            });

            const { history } = this.props;

            history.push("/");
          } else {
            if (res.data.form_error) {
              document.getElementById("form-msg").innerHTML =
                '<span class="error">' + res.data.form_error + "</span>";
            } else {
              $(".spn-error").html(res.data.message);
            }
          }
        });
    }
  }

  handleChange(field, e) {
    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({ fields });
  }

  render() {
    const { fields, onChange, onValid, onInvalid, $field, $validation } =
      this.props;
    let errMsgEmail, errMsgPassword;
    return (
      <div
        id="reset-password-popup"
        className="white-popup mfp-hide popup_sec resetpw_popup changepw_popup"
      >
        <div className="pop-whole full-login-new">
          <div className="full-login-rhs full-login">
            <div className="full-login-rhs-header">
              <h3 className="title1 text-center">Reset Password</h3>
            </div>

            <div className="popup-body">
              <span className="spn-error alert alert_success"></span>

              <div className="form-group">
                <div className="focus-out">
                  <input
                    type="password"
                    placeholder="New Password"
                    minlegth="6"
                    className="form-control input-focus"
                    onChange={this.handleChange.bind(this, "new_password")}
                  />
                  {this.state.errors["new_password"] ? (
                    <span className="error">
                      {this.state.errors["new_password"]}
                    </span>
                  ) : (
                    ""
                  )}
                </div>
              </div>

              <div className="form-group">
                <div className="focus-out">
                  <input
                    type="password"
                    placeholder="Confirm Password"
                    minlegth="6"
                    className="form-control input-focus"
                    onChange={this.handleChange.bind(this, "confirm_password")}
                  />
                  {this.state.errors["confirm_password"] ? (
                    <span className="error">
                      {this.state.errors["confirm_password"]}
                    </span>
                  ) : (
                    ""
                  )}
                  <div id="form-msg"></div>
                </div>
              </div>

              <div className="form-group">
                <div className="login_pop_sub">
                  <button
                    type="button"
                    className="button"
                    onClick={this.resetpasswordSubmit.bind(this)}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Header.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
};

export default withRouter(Resetpassword);
