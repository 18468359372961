/* eslint-disable */
import { takeEvery, call, put } from "redux-saga/effects";
import { GET_USERDETAILS, SET_USERDETAILS } from "../actions";
import { apiUrl } from "../components/Helpers/Config";
import Axios from "axios";
import cookie from "react-cookies";

export const watchGetUserDetails = function* () {
  yield takeEvery(GET_USERDETAILS, workerGetUserDetails);
};

function* workerGetUserDetails() {
  try {
    const uri =
      apiUrl +
      "promobuddy/getChopchopUser?chopchop_user_id=" +
      cookie.load("UserId");
    const result = yield call(Axios.get, uri);
    var resultArr = [];
    resultArr.push(result.data);
    yield put({ type: SET_USERDETAILS, value: resultArr });
  } catch {
    console.log("Get User Details Failed");
  }
}
