import { SET_BUSINESSLOGINDATA } from "../actions";

const businesslogin = (state = [], action) => {
  switch (action.type) {
    case SET_BUSINESSLOGINDATA:
      return [...action.value];
    default:
      return state;
  }
};

export default businesslogin;
