export const GET_PROMOTIONLIST = "getPromotionList";
export const SET_PROMOTIONLIST = "setPromotionList";
export const GET_VOUCHERLIST = "getVoucherList";
export const SET_VOUCHERLIST = "setVoucherList";
export const GET_CASHBACKLIST = "getCashbackList";
export const SET_CASHBACKLIST = "setCashbackList";
export const GET_RESTAURANTLIST = "getRestaurantList";
export const SET_RESTAURANTLIST = "setRestaurantList";
export const GET_TAGLIST = "getTagList";
export const SET_TAGLIST = "setTagList";
export const GET_BUSINESSREGISTRATION = "getBusinessRegistration";
export const SET_BUSINESSREGISTRATION = "setBusinessRegistration";
export const GET_BUSINESSLOGINDATA = "getBusinessLoginData";
export const SET_BUSINESSLOGINDATA = "setBusinessLoginData";
export const GET_LOGINDATA = "getLoginData";
export const SET_LOGINDATA = "setLoginData";
export const GET_REGISTRATION = "getRegistration";
export const SET_REGISTRATION = "setRegistration";
export const GET_USERDETAILS = "getUserDetails";
export const SET_USERDETAILS = "setUserDetails";
export const GET_CUSTOMERDETAILS = "getCustomerDetails";
export const SET_CUSTOMERDETAILS = "setCustomerDetails";
export const GET_USERPROMOTIONLIST = "getUserPromotionList";
export const SET_USERPROMOTIONLIST = "setUserPromotionList";
export const GET_USERVOUCHERLIST = "getUserVoucherList";
export const SET_USERVOUCHERLIST = "setUserVoucherList";
export const GET_FAVOURITE = "getFavourite";
export const SET_FAVOURITE = "setFavourite";
export const GET_FAVOURITELIST = "getFavouritelist";
export const SET_FAVOURITELIST = "setFavouritelist";
export const GET_CHANGEPASSWORD = "getChangePassword";
export const SET_CHANGEPASSWORD = "setChangePassword";
export const GET_CHANGEUSERPASSWORD = "getChangeUserPassword";
export const SET_CHANGEUSERPASSWORD = "setChangeUserPassword";
export const GET_FORGET_PASSWORD = "getForgetPassword";
export const SET_FORGET_PASSWORD = "setForgetPassword";
export const GET_FORGETBUS_PASSWORD = "getForgetBusPassword";
export const SET_FORGETBUS_PASSWORD = "setForgetBusPassword";
