/* eslint-disable */

import React from "react";
import { validated } from "react-custom-validation";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Select from "react-select";
import DatePicker from "react-datepicker";
import { format } from "date-fns";
import "react-datepicker/dist/react-datepicker.css";
import noimage from "../../common/images/placeholder.jpg";
import { mediaUrl } from "../Helpers/Config";
import Sidebar from "./Sidebar";
const isEmpty = (value) => (value === "" ? "This field is required." : null);
const phonenumberPattern = /^[0-9]$/;

function validationConfig(props) {
  const { title, valid_days } = props.fields;
  return {
    fields: ["title", "valid_days"],

    validations: {
      title: [[isEmpty, title]],
      valid_days: [[isEmpty, valid_days]],
    },
  };
}

class Form extends React.Component {
  constructor(props) {
    var Maxdate = new Date();
    Maxdate.setFullYear(Maxdate.getFullYear() - 10);
    super(props);
    this.state = {
      status: "Loading",
    };
  }

  handleChange = (item, value) => {
    this.props.onChange(item, value);
    this.setState({ ...this.state, [item]: value });
  };
  opendDetailImg(detailimage) {
    this.setState({ detailimage: detailimage }, function () {
      $.magnificPopup.open({
        items: {
          src: "#imagedetails-popup",
        },
        type: "inline",
      });
    });
  }

  render() {
    const { fields, onChange, onValid, onInvalid, $field, $validation } =
      this.props;

    let errMsgTitle, errMsgValidDays;

    if ($validation.title.error.reason !== undefined) {
      errMsgTitle = $validation.title.show && (
        <span className="error">{$validation.title.error.reason}</span>
      );
    }
    if ($validation.valid_days.error.reason !== undefined) {
      errMsgValidDays = $validation.valid_days.show && (
        <span className="error">{$validation.valid_days.error.reason}</span>
      );
    }

    return (
      <div className="container-one">
        <div className="tab-content">
          <div className="tab-pane account-tab-section fade in active">
            <div className="tab_mobrow main_tabin">
              <div className="account_sec">
                <Sidebar pageName="rewards" {...this.props} />
                <div className="accsec_right">
                  <form action="#" method="post" encType="multipart/form-data">
                    <div className="acc-inform">
                      {/* profile-info-div - end */}
                      <div className="profile-info-div">
                        <h4 className="form_grouptt">
                          Your Reward Information
                        </h4>
                        <div className="form-group half-fg">
                          <div className="row">
                            <div className="col-md-6">
                              <div className="focus-out">
                                <input
                                  type="text"
                                  placeholder="Promotion Title"
                                  name="title"
                                  className="form-control input-focus"
                                  value={fields.title || ""}
                                  {...$field("title", (e) =>
                                    onChange("title", e.target.value)
                                  )}
                                />
                                {errMsgTitle}
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="focus-out">
                                <input
                                  type="number"
                                  min={1}
                                  placeholder="Valid Days"
                                  name="valid_days"
                                  className="form-control input-focus"
                                  value={fields.valid_days || ""}
                                  {...$field("valid_days", (e) =>
                                    onChange("valid_days", e.target.value)
                                  )}
                                />
                                {errMsgValidDays}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="form-group half-fg">
                          <div className="row">
                            <div className="col-md-6">
                              <div className="focus-out">
                                <input
                                  type="file"
                                  name="reward_image"
                                  accept="image/*"
                                  id="reward_image"
                                  className="form-control input-focus"
                                />
                              </div>
                              {fields.rewardimage !== "" &&
                                fields.rewardimage !== null && (
                                  <a
                                    href={void 0}
                                    onClick={this.opendDetailImg.bind(
                                      this,
                                      fields.rewardimage
                                    )}
                                    className="view-image"
                                  >
                                    View Image
                                  </a>
                                )}
                            </div>
                          </div>
                        </div>
                        <div className="form-group full-fg">
                          <div className="row">
                            <div className="col-md-12">
                              <div className="focus-out">
                                <textarea
                                  type="text"
                                  placeholder="About Information"
                                  name="description"
                                  defaultValue={fields.description || ""}
                                  {...$field("description", (e) =>
                                    onChange("description", e.target.value)
                                  )}
                                  className="form-control input-focus"
                                ></textarea>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="form-group btn-last-group">
                          <div className="row">
                            <div className="col-md-6">
                              <button
                                type="button"
                                className="myaccount_update button"
                                onClick={(e) => {
                                  e.preventDefault();
                                  this.props.$submit(onValid, onInvalid);
                                }}
                              >
                                Submit
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        id="imagedetails-popup"
                        className="white-popup mfp-hide popup_sec detail-popup"
                      >
                        {this.state.detailimage !== "" && (
                          <div className="cashback-image">
                            <img
                              src={
                                mediaUrl +
                                "/chopchop/cashback/" +
                                this.state.detailimage
                              }
                            />
                          </div>
                        )}
                      </div>

                      {/* profile-info-div - end */}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
Form = validated(validationConfig)(Form);

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};
Form.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
};
export default connect(mapStateToProps, mapDispatchToProps)(Form);
