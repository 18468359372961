import { all } from "redux-saga/effects";
import { watchGetPromotionList } from "./promotionlist";
import { watchGetVoucherList } from "./voucherlist";
import { watchGetCashbackList } from "./cashbacklist";
import { watchGetRestaurantList } from "./restaurantlist";
import { watchGetTagList } from "./taglist";
import { watchGetBusinessRegistration } from "./businessregistration";
import { watchGetBusinessLoginData } from "./businesslogin";
import { watchGetLoginData } from "./login";
import { watchGetRegistration } from "./registration";
import { watchGetUserDetails } from "./userdetails";
import { watchGetCustomerDetails } from "./customerdetails";
import { watchGetUserPromotionList } from "./userpromotionlist";
import { watchGetUserVoucherList } from "./uservoucherlist";
import { watchGetFavourite } from "./favourite";
import { watchGetFavouritelist } from "./favouritelist";
import { watchGetChangePassword } from "./changepassword";
import { watchGetChangeUserPassword } from "./changeuserpassword";
import { watchGetForgetPassword } from "./forgetpassword";
import { watchGetForgetBusPassword } from "./forgetbuspassword";

export default function* () {
  yield all([
    watchGetPromotionList(),
    watchGetVoucherList(),
    watchGetCashbackList(),
    watchGetRestaurantList(),
    watchGetTagList(),
    watchGetBusinessRegistration(),
    watchGetBusinessLoginData(),
    watchGetLoginData(),
    watchGetRegistration(),
    watchGetUserDetails(),
    watchGetCustomerDetails(),
    watchGetUserPromotionList(),
    watchGetUserVoucherList(),
    watchGetFavourite(),
    watchGetFavouritelist(),
    watchGetChangePassword(),
    watchGetChangeUserPassword(),
    watchGetForgetPassword(),
    watchGetForgetBusPassword(),
  ]);
}
