import { SET_USERVOUCHERLIST } from "../actions";

const uservoucherlist = (state = [], action) => {
  switch (action.type) {
    case SET_USERVOUCHERLIST:
      return [...action.value];
    default:
      return state;
  }
};

export default uservoucherlist;
