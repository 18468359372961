/* eslint-disable */
import React, { Component } from "react";
import { apiUrl } from "../Helpers/Config";
import Axios from "axios";
import cookie from "react-cookies";
import update from "immutability-helper";
import { format } from "date-fns";
import { hideLoader, showAlert, showLoader } from "../Helpers/SettingHelper";

import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
/* import modules */
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import {
  GET_USERDETAILS,
  GET_USERPROMOTIONLIST,
  GET_USERVOUCHERLIST,
  GET_CUSTOMERDETAILS,
} from "../../actions";
import Form from "./Form";
import Businessform from "./Businessform";
import Promotionform from "./Promotionform";
import Voucherform from "./Voucherform";
import Rewardform from "./Rewardform";
import Formcustomer from "./Formcustomer";
import warningImg from "../../common/images/warning.svg";
import { fi } from "date-fns/locale";

var qs = require("qs");
class Myaccount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {
        firstname: "",
        lastname: "",
        email: "",
        mobile: "",
      },
      businessfields: {
        firstname: "",
        lastname: "",
        email: "",
        mobile: "",
      },
      businessinfofields: {
        company_name: "",
        address: "",
        postal_code: "",
        order_url: "",
        availability: "",
        tag: "",
        delivery_start: "",
        min_order_amount: "",
        description: "",
        restarentimage: "",
      },
      promotions: [],
      promofields: {
        title: "",
        promocode: "",
        availability: "",
        end_date: "",
        promo_url: "",
        description: "",
        status: { label: "Active", value: "A" },
        promotion_image: "",
        promotionID: "",
      },
      showpromofrm: false,
      removePromotionID: "",

      vouchers: [],
      voucherfields: {
        title: "",
        availability: "",
        end_date: "",
        voucher_url: "",
        description: "",
        status: { label: "Active", value: "A" },
        voucher_image: "",
        voucherID: "",
      },
      showvoucherfrm: false,
      removeVoucherID: "",
      rewardfields: {
        title: "",
        valid_days: "",
        description: "",
        rewardimage: "",
      },
      status: "OK",
    };

    if (cookie.load("UserId") == "" || cookie.load("UserId") == undefined) {
      cookie.save("loginpopupTrigger", "login", { path: "/" });
      props.history.push("/");
    } else {
      if (cookie.load("logintype") === "customer") {
        this.props.getCustomerDetails();
      } else {
        this.props.getUserDetails();
        this.props.getUserPromotionList();
        this.props.getUserVoucherList();
      }
    }
  }

  componentDidMount() {
    setTimeout(function () {
      if ($(".mCustomScrollbar").length > 0) {
        $(".mCustomScrollbar").mCustomScrollbar();
      }
    }, 400);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.userdetails != this.props.userdetails) {
      if (nextProps.userdetails.length > 0) {
        if (nextProps.userdetails[0].status === "ok") {
          var userdetails = nextProps.userdetails[0].result_set;
          var chopchop_availability = [];
          if (userdetails.chopchop_availability !== "") {
            userdetails.chopchop_availability.split(",").map((item) => {
              chopchop_availability.push({
                value: item,
                label: item,
              });
            });
          }
          this.setState(
            update(this.state, {
              businessfields: {
                firstname: { $set: userdetails.chopchop_first_name },
                lastname: { $set: userdetails.chopchop_last_name },
                email: { $set: userdetails.chopchop_email },
                mobile: { $set: userdetails.chopchop_mobile },
              },
              businessinfofields: {
                company_name: { $set: userdetails.chopchop_company_name },
                address: { $set: userdetails.chopchop_address },
                postal_code: { $set: userdetails.chopchop_postal_code },
                order_url: { $set: userdetails.chopchop_order_url },
                availability: { $set: chopchop_availability },
                delivery_start: { $set: userdetails.chopchop_delivery_start },
                min_order_amount: {
                  $set: userdetails.chopchop_min_order_amount,
                },
                description: { $set: userdetails.chopchop_description },
                tag: { $set: userdetails.tag },
                restarentimage: { $set: userdetails.chopchop_logo },
              },
              rewardfields: {
                title: { $set: userdetails.chopchop_cashback_title },
                valid_days: { $set: userdetails.chopchop_cashback_valid_days },
                description: {
                  $set: userdetails.chopchop_cashback_description,
                },
                rewardimage: { $set: userdetails.chopchop_cashback_image },
              },
            })
          );
        }
      }
    }
    if (nextProps.customerdetails != this.props.customerdetails) {
      if (nextProps.customerdetails.length > 0) {
        if (nextProps.customerdetails[0].status === "ok") {
          var customerdetails = nextProps.customerdetails[0].result_set;
          this.setState(
            update(this.state, {
              fields: {
                firstname: { $set: customerdetails.chopchop_first_name },
                lastname: { $set: customerdetails.chopchop_last_name },
                email: { $set: customerdetails.chopchop_email },
                mobile: { $set: customerdetails.chopchop_mobile },
              },
            })
          );
        }
      }
    }
    if (this.state.promotions !== nextProps.userpromotionlist) {
      if (nextProps.userpromotionlist.length > 0) {
        if (nextProps.userpromotionlist[0].status === "ok") {
          var userpromotionlist = nextProps.userpromotionlist[0].result_set;
          this.setState({ promotions: userpromotionlist });
        }
      }
    }
    if (this.state.vouchers !== nextProps.uservoucherlist) {
      if (nextProps.uservoucherlist.length > 0) {
        if (nextProps.uservoucherlist[0].status === "ok") {
          var uservoucherlist = nextProps.uservoucherlist[0].result_set;
          this.setState({ vouchers: uservoucherlist });
        }
      }
    }
  }
  sateValChange = (field, value) => {
    if (field === "showpromofrm") {
      this.setState({ showpromofrm: value });
    } else if (field === "showvoucherfrm") {
      this.setState({ showvoucherfrm: value });
    }
  };
  fieldChange = (field, value) => {
    this.setState(update(this.state, { fields: { [field]: { $set: value } } }));
  };

  /* To Submit the personal informartion form */
  handleFormSubmit = () => {
    const formPayload = this.state.fields;
    showLoader("myaccount_update", "class");
    var postObject = {
      chopchop_first_name: formPayload.firstname,
      chopchop_last_name: formPayload.lastname,
      chopchop_mobile: formPayload.mobile,
      chopchop_email: formPayload.email,
      chopchop_customer_id: cookie.load("UserId"),
    };

    Axios.post(
      apiUrl + "promobuddy/updatecustomer",
      qs.stringify(postObject)
    ).then((res) => {
      hideLoader("myaccount_update", "class");
      if (res.data.status === "ok") {
        showAlert("Success", res.data.message);
        $.magnificPopup.open({
          items: {
            src: ".alert_popup",
          },
          type: "inline",
        });
      } else {
        var errormsg =
          res.data.form_error !== "" ? res.data.form_error : res.data.message;
        showAlert("Error", errormsg);
        $.magnificPopup.open({
          items: {
            src: ".alert_popup",
          },
          type: "inline",
        });
      }
    });
  };

  fieldBusinessChange = (field, value) => {
    this.setState(
      update(this.state, { businessfields: { [field]: { $set: value } } })
    );
  };

  /* To Submit the personal informartion form */
  handleBusinessFormSubmit = () => {
    const formPayload = this.state.businessfields;
    showLoader("myaccount_update", "class");
    var postObject = {
      chopchop_first_name: formPayload.firstname,
      chopchop_last_name: formPayload.lastname,
      chopchop_mobile: formPayload.mobile,
      chopchop_email: formPayload.email,
      chopchop_user_id: cookie.load("UserId"),
    };

    Axios.post(apiUrl + "promobuddy/updateuser", qs.stringify(postObject)).then(
      (res) => {
        hideLoader("myaccount_update", "class");
        if (res.data.status === "ok") {
          showAlert("Success", res.data.message);
          $.magnificPopup.open({
            items: {
              src: ".alert_popup",
            },
            type: "inline",
          });
        } else {
          var errormsg =
            res.data.form_error !== "" ? res.data.form_error : res.data.message;
          showAlert("Error", errormsg);
          $.magnificPopup.open({
            items: {
              src: ".alert_popup",
            },
            type: "inline",
          });
        }
      }
    );
  };

  fieldBusinessInfoChange = (field, value) => {
    this.setState(
      update(this.state, { businessinfofields: { [field]: { $set: value } } })
    );
  };

  /* To Submit the personal informartion form */
  handleBusinessInfoFormSubmit = () => {
    const forminfoPayload = this.state.businessinfofields;

    showLoader("myaccount_update", "class");
    var availability = "";
    if (forminfoPayload.availability.length > 0) {
      forminfoPayload.availability.map((item, index) => {
        if (index > 0) {
          availability += ",";
        }
        availability += item.value;
      });
    }

    var tag = "";
    if (forminfoPayload.tag.length > 0) {
      forminfoPayload.tag.map((item, index) => {
        if (index > 0) {
          tag += ",";
        }
        tag += item.value;
      });
    }
    var formData = new FormData();
    var imagefile = document.querySelector("#restaurant_image");
    if (imagefile.files.length > 0) {
      formData.append("image", imagefile.files[0]);
    }

    formData.append("company_name", forminfoPayload.company_name);
    formData.append("address", forminfoPayload.address);
    formData.append("postal_code", forminfoPayload.postal_code);
    formData.append("order_url", forminfoPayload.order_url);
    formData.append("availability", availability);
    formData.append("tag", tag);
    formData.append("delivery_start", forminfoPayload.delivery_start);
    formData.append("min_order_amount", forminfoPayload.min_order_amount);
    formData.append("description", forminfoPayload.description);
    formData.append("chopchop_user_id", cookie.load("UserId"));

    Axios.post(apiUrl + "promobuddy/updateuserinfo", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }).then((res) => {
      hideLoader("myaccount_update", "class");
      if (res.data.status === "ok") {
        showAlert("Success", res.data.message);
        $.magnificPopup.open({
          items: {
            src: ".alert_popup",
          },
          type: "inline",
        });
      } else {
        var errormsg =
          res.data.form_error !== "" ? res.data.form_error : res.data.message;
        showAlert("Error", errormsg);
        $.magnificPopup.open({
          items: {
            src: ".alert_popup",
          },
          type: "inline",
        });
      }
    });
  };

  fieldPromoChange = (field, value) => {
    this.setState(
      update(this.state, { promofields: { [field]: { $set: value } } })
    );
  };

  handlePromoFormSubmit = () => {
    const formpromoPayload = this.state.promofields;
    showLoader("myaccount_update", "class");
    var availability = "";
    if (formpromoPayload.availability.length > 0) {
      formpromoPayload.availability.map((item, index) => {
        if (index > 0) {
          availability += ",";
        }
        availability += item.value;
      });
    }
    var promo_end_date = "";
    if (formpromoPayload.end_date !== "") {
      var promo_end_date = new Date(formpromoPayload.end_date);
      promo_end_date = format(promo_end_date, "yyyy-MM-dd hh:mm") + ":00";
    }

    var formData = new FormData();
    var imagefile = document.querySelector("#promo_image");
    if (imagefile.files.length > 0) {
      formData.append("image", imagefile.files[0]);
    }
    formData.append("promotion_title", formpromoPayload.title);
    formData.append("promo_code", formpromoPayload.promocode);
    formData.append("availability", availability);
    formData.append("promotion_end_date", promo_end_date);
    formData.append("promotion_link", formpromoPayload.promo_url);
    formData.append("promotion_description", formpromoPayload.description);
    formData.append("status", formpromoPayload.status.value);
    formData.append("chopchop_user_id", cookie.load("UserId"));
    var promosionURL = "promobuddy/createpromo";
    if (formpromoPayload.promotionID !== "") {
      formData.append("promotion_id", formpromoPayload.promotionID);
      promosionURL = "promobuddy/updatepromo";
    }
    Axios.post(apiUrl + promosionURL, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }).then((res) => {
      hideLoader("myaccount_update", "class");
      this.props.getUserPromotionList();
      if (res.data.status === "ok") {
        showAlert("Success", res.data.message);
        $.magnificPopup.open({
          items: {
            src: ".alert_popup",
          },
          type: "inline",
        });
        this.setState(
          update(this.state, {
            promofields: {
              title: { $set: "" },
              promocode: { $set: "" },
              end_date: { $set: "" },
              promo_url: { $set: "" },
              description: { $set: "" },
              status: { $set: "" },
              availability: { $set: "" },
              promotionID: { $set: "" },
              promotion_image: { $set: "" },
            },
          })
        );

        this.setState({ showpromofrm: false });
      } else {
        var errormsg =
          res.data.form_error !== "" ? res.data.form_error : res.data.message;
        showAlert("Error", errormsg);
        $.magnificPopup.open({
          items: {
            src: ".alert_popup",
          },
          type: "inline",
        });
      }
    });
  };

  promoEdit = (item) => {
    var status = "";
    if (item.promotion_status === "A") {
      status = { label: "Active", value: "A" };
    } else if (item.promotion_status === "I") {
      status = { label: "Inactive", value: "I" };
    }
    var availability = [];
    if (item.availability_name !== "") {
      item.availability_name.split(",").map((avail) => {
        availability.push({ label: avail, value: avail });
      });
    }
    this.setState(
      update(this.state, {
        promofields: {
          title: { $set: item.promotion_title },
          promocode: { $set: item.promotion_name },
          end_date: { $set: item.promotion_end_date },
          promo_url: { $set: item.promotion_link },
          description: { $set: item.promotion_description },
          status: { $set: status },
          availability: { $set: availability },
          promotionID: { $set: item.promotion_id },
          promotion_image: { $set: item.promotion_image },
        },
      })
    );
  };
  confirmPopup(e) {
    e.preventDefault();
    if (this.state.removePromotionID !== "") {
      this.conformremovePromo();
    }
    if (this.state.removeVoucherID !== "") {
      this.conformremoveVoucher();
    }
  }
  removePromo = (promotion_id) => {
    this.setState({ removePromotionID: promotion_id }, function () {
      $.magnificPopup.open({
        items: {
          src: "#warning-popup",
        },
        type: "inline",
      });
    });
  };
  conformremovePromo() {
    showLoader("myaccount_update", "class");
    var postObject = {
      promotion_id: this.state.removePromotionID,
      chopchop_user_id: cookie.load("UserId"),
    };

    Axios.post(
      apiUrl + "promobuddy/deletepromo",
      qs.stringify(postObject)
    ).then((res) => {
      $.magnificPopup.close();
      this.props.getUserPromotionList();
      if (res.data.status === "ok") {
        showAlert("Success", res.data.message);
        $.magnificPopup.open({
          items: {
            src: ".alert_popup",
          },
          type: "inline",
        });
      } else {
        var errormsg =
          res.data.form_error !== "" ? res.data.form_error : res.data.message;
        showAlert("Error", errormsg);
        $.magnificPopup.open({
          items: {
            src: ".alert_popup",
          },
          type: "inline",
        });
      }
    });
  }

  fieldVoucherChange = (field, value) => {
    this.setState(
      update(this.state, { voucherfields: { [field]: { $set: value } } })
    );
  };

  handleVoucherFormSubmit = () => {
    const formvoucherPayload = this.state.voucherfields;
    showLoader("myaccount_update", "class");
    var availability = "";
    if (formvoucherPayload.availability.length > 0) {
      formvoucherPayload.availability.map((item, index) => {
        if (index > 0) {
          availability += ",";
        }
        availability += item.value;
      });
    }
    var voucher_end_date = "";
    if (formvoucherPayload.end_date !== "") {
      var voucher_end_date = new Date(formvoucherPayload.end_date);
      voucher_end_date = format(voucher_end_date, "yyyy-MM-dd hh:mm") + ":00";
    }

    var formData = new FormData();
    var imagefile = document.querySelector("#voucher_image");
    if (imagefile.files.length > 0) {
      formData.append("image", imagefile.files[0]);
    }
    formData.append("voucher_title", formvoucherPayload.title);
    formData.append("availability", availability);
    formData.append("voucher_end_date", voucher_end_date);
    formData.append("voucher_link", formvoucherPayload.voucher_url);
    formData.append("voucher_description", formvoucherPayload.description);
    formData.append("status", formvoucherPayload.status.value);
    formData.append("chopchop_user_id", cookie.load("UserId"));
    var voucherURL = "promobuddy/createvoucher";
    if (formvoucherPayload.voucherID !== "") {
      formData.append("voucher_id", formvoucherPayload.voucherID);
      voucherURL = "promobuddy/updatevoucher";
    }
    Axios.post(apiUrl + voucherURL, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }).then((res) => {
      hideLoader("myaccount_update", "class");
      this.props.getUserVoucherList();
      if (res.data.status === "ok") {
        showAlert("Success", res.data.message);
        $.magnificPopup.open({
          items: {
            src: ".alert_popup",
          },
          type: "inline",
        });
        this.setState(
          update(this.state, {
            voucherfields: {
              title: { $set: "" },
              end_date: { $set: "" },
              voucher_url: { $set: "" },
              description: { $set: "" },
              status: { $set: "" },
              availability: { $set: "" },
              voucherID: { $set: "" },
              voucher_image: { $set: "" },
            },
          })
        );

        this.setState({ showvoucherfrm: false });
      } else {
        var errormsg =
          res.data.form_error !== "" ? res.data.form_error : res.data.message;
        showAlert("Error", errormsg);
        $.magnificPopup.open({
          items: {
            src: ".alert_popup",
          },
          type: "inline",
        });
      }
    });
  };

  voucherEdit = (item) => {
    var status = "";
    if (item.voucher_status === "A") {
      status = { label: "Active", value: "A" };
    } else if (item.voucher_status === "I") {
      status = { label: "Inactive", value: "I" };
    }
    var availability = [];
    if (item.availability_name !== "") {
      item.availability_name.split(",").map((avail) => {
        availability.push({ label: avail, value: avail });
      });
    }
    this.setState(
      update(this.state, {
        voucherfields: {
          title: { $set: item.voucher_title },
          end_date: { $set: item.voucher_end_date },
          voucher_url: { $set: item.voucher_link },
          description: { $set: item.voucher_description },
          status: { $set: status },
          availability: { $set: availability },
          voucherID: { $set: item.voucher_id },
          voucher_image: { $set: item.voucher_image },
        },
      })
    );
  };
  removeVoucher = (voucher_id) => {
    this.setState({ removeVoucherID: voucher_id }, function () {
      $.magnificPopup.open({
        items: {
          src: "#warning-popup",
        },
        type: "inline",
      });
    });
  };
  conformremoveVoucher() {
    showLoader("myaccount_update", "class");
    var postObject = {
      voucher_id: this.state.removeVoucherID,
      chopchop_user_id: cookie.load("UserId"),
    };

    Axios.post(
      apiUrl + "promobuddy/deletevoucher",
      qs.stringify(postObject)
    ).then((res) => {
      $.magnificPopup.close();
      this.props.getUserVoucherList();
      if (res.data.status === "ok") {
        showAlert("Success", res.data.message);
        $.magnificPopup.open({
          items: {
            src: ".alert_popup",
          },
          type: "inline",
        });
      } else {
        var errormsg =
          res.data.form_error !== "" ? res.data.form_error : res.data.message;
        showAlert("Error", errormsg);
        $.magnificPopup.open({
          items: {
            src: ".alert_popup",
          },
          type: "inline",
        });
      }
    });
  }

  fieldRewardChange = (field, value) => {
    this.setState(
      update(this.state, { rewardfields: { [field]: { $set: value } } })
    );
  };

  handleRewardFormSubmit = () => {
    const formrewardPayload = this.state.rewardfields;
    showLoader("myaccount_update", "class");

    var formData = new FormData();
    var imagefile = document.querySelector("#reward_image");
    if (imagefile.files.length > 0) {
      formData.append("image", imagefile.files[0]);
    }
    formData.append("cashback_title", formrewardPayload.title);
    formData.append("cashback_valid_days", formrewardPayload.valid_days);
    formData.append("cashback_description", formrewardPayload.description);
    formData.append("chopchop_user_id", cookie.load("UserId"));
    var promosionURL = "promobuddy/updaterewards";
    Axios.post(apiUrl + promosionURL, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }).then((res) => {
      hideLoader("myaccount_update", "class");
      this.props.getUserPromotionList();
      if (res.data.status === "ok") {
        showAlert("Success", res.data.message);
        $.magnificPopup.open({
          items: {
            src: ".alert_popup",
          },
          type: "inline",
        });
        this.setState(
          update(this.state, {
            promofields: {
              title: { $set: "" },
              promocode: { $set: "" },
              end_date: { $set: "" },
              promo_url: { $set: "" },
              description: { $set: "" },
              status: { $set: "" },
              availability: { $set: "" },
              promotionID: { $set: "" },
              promotion_image: { $set: "" },
            },
          })
        );

        this.setState({ showpromofrm: false });
      } else {
        var errormsg =
          res.data.form_error !== "" ? res.data.form_error : res.data.message;
        showAlert("Error", errormsg);
        $.magnificPopup.open({
          items: {
            src: ".alert_popup",
          },
          type: "inline",
        });
      }
    });
  };

  render() {
    return (
      <div className="myacc-main-div">
        <Header {...this.props} />
        <div className="common-inner-blckdiv">
          <div className="common-inner-banner">
            <p>My Account</p>
            <span>Your account informations you can see here</span>
          </div>
        </div>
        <div className="innersection_wrap myadmin_wrap">
          <div className="mainacc_menusec">
            {/* container div - start */}
            <div className="container">
              <div className="mainacc_menucontent">
                <div className="main_tabsec">
                  <div className="order-tab-section">
                    <div className="mainacc_mobrow">
                      <div className="tab_sec main_tabsec_inner">
                        <div className="myacc_filter">
                          <div className="tab_sec filter_tabsec">
                            {this.props.match.path === "/my-account" && (
                              <Form
                                {...this.props}
                                fields={this.state.businessfields}
                                onChange={this.fieldBusinessChange}
                                onValid={this.handleBusinessFormSubmit}
                                onInvalid={() => console.log("Form invalid!")}
                              />
                            )}
                            {this.props.match.path === "/business-info" && (
                              <Businessform
                                {...this.props}
                                fields={this.state.businessinfofields}
                                onChange={this.fieldBusinessInfoChange}
                                onValid={this.handleBusinessInfoFormSubmit}
                                onInvalid={(e) =>
                                  console.log(e, "Form invalid!")
                                }
                              />
                            )}
                            {this.props.match.path === "/promotions" && (
                              <Promotionform
                                {...this.props}
                                fields={this.state.promofields}
                                promotions={this.state.promotions}
                                onChange={this.fieldPromoChange}
                                onValid={this.handlePromoFormSubmit}
                                onInvalid={(e) =>
                                  console.log(e, "Form invalid!")
                                }
                                onEdit={this.promoEdit}
                                showpromofrm={this.state.showpromofrm}
                                removePromo={this.removePromo}
                                sateValChange={this.sateValChange}
                              />
                            )}
                            {this.props.match.path === "/rewards" && (
                              <Rewardform
                                {...this.props}
                                fields={this.state.rewardfields}
                                onChange={this.fieldRewardChange}
                                onValid={this.handleRewardFormSubmit}
                                onInvalid={(e) =>
                                  console.log(e, "Form invalid!")
                                }
                              />
                            )}

                            {this.props.match.path === "/vouchers" && (
                              <Voucherform
                                {...this.props}
                                fields={this.state.voucherfields}
                                vouchers={this.state.vouchers}
                                onChange={this.fieldVoucherChange}
                                onValid={this.handleVoucherFormSubmit}
                                onInvalid={(e) =>
                                  console.log(e, "Form invalid!")
                                }
                                onEdit={this.voucherEdit}
                                showvoucherfrm={this.state.showvoucherfrm}
                                removeVoucher={this.removeVoucher}
                                sateValChange={this.sateValChange}
                              />
                            )}
                            {this.props.match.path === "/myaccount" && (
                              <Formcustomer
                                {...this.props}
                                fields={this.state.fields}
                                onChange={this.fieldChange}
                                onValid={this.handleFormSubmit}
                                onInvalid={() => console.log("Form invalid!")}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* container div - end */}
          </div>
        </div>

        <div
          id="warning-popup"
          className="white-popup mfp-hide popup_sec warning_popup"
        >
          <div className="custom_alert">
            <div className="custom_alertin">
              <div className="alert_height">
                <div className="alert_header">Warning</div>
                <div className="alert_body">
                  <img className="warning-popup-img" src={warningImg} />
                  {this.state.removePromotionID !== "" && (
                    <p>Are you sure want remove this promo</p>
                  )}
                  <div className="alt_btns">
                    <a
                      href={void 0}
                      className="button button-left popup-modal-dismiss"
                    >
                      No
                    </a>
                    <a
                      href="/"
                      onClick={this.confirmPopup.bind(this)}
                      className="button button-right"
                    >
                      Yes
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userdetails: state.userdetails,
    userpromotionlist: state.userpromotionlist,
    uservoucherlist: state.uservoucherlist,
    customerdetails: state.customerdetails,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getUserDetails: () => {
      dispatch({ type: GET_USERDETAILS });
    },
    getCustomerDetails: () => {
      dispatch({ type: GET_CUSTOMERDETAILS });
    },
    getUserPromotionList: () => {
      dispatch({ type: GET_USERPROMOTIONLIST });
    },
    getUserVoucherList: () => {
      dispatch({ type: GET_USERVOUCHERLIST });
    },
  };
};

Myaccount.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Myaccount)
);
